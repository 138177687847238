@import '../../assets/styles/colors';
@import '../../assets/styles/variables';

.table {
  table tbody {
    max-height: 50vh !important;
  }

  tr {
    td,
    th {
      &:nth-child(1) {
        width: 10%;
      }

      &:nth-child(2) {
        width: 20%;
      }

      &:nth-child(3) {
        width: 15%;
      }

      &:nth-child(4) {
        width: 10%;
      }

      &:nth-child(5) {
        width: 10%;
      }

      &:nth-child(6) {
        width: 10%;
      }

      &:nth-child(7) {
        width: 10%;
      }

      &:nth-child(8) {
        width: 4%;
      }
      &:nth-child(9) {
        width: 11%;
      }
    }

    td {
      :global {
        .MuiSelect-select {
          padding: 2px 7px;
        }
        .MuiSelect-icon {
          top: 4px;
          padding: 2px 5px;
        }
        .MuiCheckbox-root {
          padding: 0;
        }
        .MuiFormControlLabel-root {
          margin-left: 0;
        }
      }
    }

    &.newRow {
      background-color: $mabel !important;
    }
  }
}

.skuTypesList {
  width: 97%;
}

.fieldWithButton {
  :global {
    .MuiFormControl-root {
      .MuiInput-root {
        width: 97%;
        max-width: unset;
      }
    }
  }
}

.actionBtn {
  cursor: pointer;
}

.activeToolbar {
  > div {
    &:nth-child(1) {
      width: 55%;
    }

    &:nth-child(2) {
      width: 10%;
    }
  }
}

.updateWarning {
  font-size: 14px;
  line-height: 25px;
  height: 25px;
}


.modalLink{
  color: $primary-color;
  cursor: pointer;
  text-align: right;
  margin-top: 15px;
}