@import "../../assets/styles/colors";
@import "../../assets/styles/variables";

.scrollableTableBody {
  overflow-y: scroll;
  max-height: 240px !important;
}

.activeToolbar {
  padding: 0 32px 0 24px;
  order: 4;

  > div {
    width: 11%;

    &:nth-child(1) {
      width: 23%;
    }
  }
}

.table {
  thead {
    tr {
      margin: -8px 0;
    }
  }

  tr {
    td, th {
      &:nth-child(1) {
        width: 23% !important;
      }

      &:nth-child(2) {
        width: auto !important;
      }
    }
  }
}
