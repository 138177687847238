@import "../../assets/styles/colors";
@import "../../assets/styles/variables";
@import "../../assets/styles/mixins";

.selectField {
  width: 135px;
  height: 36px;

  @include select-field-with-top-label;
}

.table {
  overflow: auto;
  max-height: 330px;
  width: calc(100% + 10px);
  @include thin-scrollbar;

  thead {
    width: 100% !important;
    border-spacing: 0 !important;
    margin: 0 !important;
  }

  tbody {
    display: table !important;
    max-height: initial !important;
    border-collapse: separate !important;
    position: relative;
  }

  tr {
    td,
    th {
      &:nth-child(1) {
        position: sticky !important;
        background-color: inherit !important;
        left: 0;
        z-index: 1;
        min-width: 330px;
        width: 330px;
        text-align: left;
      }

      &:nth-child(n+2) {
        min-width: 155px;
        width: 155px;
      }
    }
  }
}

.fullScreenModal {
  overflow-y: auto;

  > div {
    padding: 20px;
  }

  .table {
    max-height: calc(95vh - 179px) !important;
    margin-left: -10px;
  }
}

.activeToolbar {
  margin-right: -8px;
  overflow-x: auto !important;
  display: inline-flex !important;
  width: calc(100% + 8px) !important;
  flex-shrink: 0;

  > div {
    flex-shrink: 0;

    &:nth-child(1) {
      position: sticky !important;
      background-color: $white !important;
      left: 0;
      min-width: 330px;
      width: 330px;
      text-align: left;
      z-index: 1;
      flex: 2.2;
    }

    &:nth-child(n+2) {
      min-width: 155px;
      width: 155px;
      flex: 1;
    }
  }
}

.chartContainer {
  height: calc(100vh - 500px);
  min-height: 400px;
}

.fullScreen {
  height: calc(100vh - 230px);
}


.editLink {
  font-size: 12px;
  line-height: 23px;
  margin-left: 10px;
}
