@import '../../assets/styles/colors.scss';
@import '../../assets/styles/variables.scss';
@import '../../assets/styles/mixins';

.disabledMention {
  pointer-events: none;
  cursor: default;
  color: #575f67;
  display: inline-block;
  background: #e6f3ff;
  padding: 0 2px;
  border-radius: 2px;
  text-decoration: none;
}

.disabledMentionSuggestions {
  border: 1px solid #eeeeee;
  position: absolute;
  min-width: 220px;
  max-width: 440px;
  background: #ffffff;
  border-radius: 2px;
  box-shadow: 0 4px 30px 0 rgba(220, 220, 220, 1);
  cursor: pointer;
  padding-top: 8px;
  padding-bottom: 8px;
  z-index: 100001;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  transform: scale(0);
}

.mentionsContainer {
  > div {
    z-index: 100001;
  }
}

.disabledMentionSuggestionsEntry {
  padding: 7px 10px 3px 10px;
  transition: background-color 0.4s cubic-bezier(0.27, 1.27, 0.48, 0.56);
}

.disabledMentionSuggestionsEntryFocused {
  padding: 7px 10px 3px 10px;
  transition: background-color 0.4s cubic-bezier(0.27, 1.27, 0.48, 0.56);
  background-color: #e6f3ff;
}

.disabledMentionSuggestionsEntryText {
  display: inline-block;
  margin-left: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 368px;
  font-size: 0.9em;
  margin-bottom: 0.2em;
}

.child {
  &:before {
    content: '';
    display: block;
    width: calc(100% - 20px);
    height: 1px;
    background: $gallery;
    margin-left: 10px;
  }

  .noteTopRow {
    background: transparent;
  }
}

.childrenContainer {
  padding: 0;
  margin: 0;
  overflow: visible;
}

.commentContainer {
  margin: 5px 16px;
  padding: 0;
  overflow: visible;

  :global {
    .DraftEditor-root {
      max-height: 200px !important;
      overflow: auto;
    }
  }
}

.comment {
  margin: 0;
  padding: 0;
  overflow: visible;
}

.postButton {
  z-index: 1;
  border: none;
  bottom: 0;
  cursor: pointer;
  display: flex;
}

.cancelButton {
  button {
    border-color: transparent;
    color: $secondary-color;
    font-weight: 400;

    &:hover {
      border-color: $secondary-color;
      color: $secondary-color;
    }
  }
}

.removeAttachment {
  position: absolute;
  left: 95%;
  top: -9%;
  background-color: $blue-gray;
  color: $primary-color;
}

.editor {
  display: flex;
  flex-direction: column;
  padding: 0 10px;
  background: $white;
  border-radius: $small-border-radius;
  max-height: none;
  margin: 0;

  div > div {
    margin: 0;
    vertical-align: middle;
    cursor: text;
  }

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    padding: 0 1px;
    border: none;
  }

  &::-webkit-scrollbar-thumb {
    border: 1px solid transparent;
    background-color: $scrollbar-color;
    border-radius: 8px;
    background-clip: content-box;
  }

  ul,
  ol {
    margin: 0px;
  }
}

.isFullScreenEditor {
  padding: 0 90px 0 0 !important;
}

.fullScreenTextCursor {
  cursor: text;
  max-width: calc(80vw - 126px);
}

.noteEditorEditor {
  min-height: 100px;
  // max-height: 100px;
}

.readOnly {
  padding-left: 0px;
  padding-right: 0px;
  margin: 0px;

  div > div {
    margin: 0;
    vertical-align: middle;
  }
}

.noteEditor {
  :global {
    .DraftEditor-root {
      max-height: 100vh;
      overflow: hidden;
      padding-top: 5px !important;
      flex: 1;
      transition: max-height 300ms ease-in-out;
    }
  }
}

.commentEditor {
  padding: 0;
  margin: 0;
}

.commentEditorEditor {
  margin: 0;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  padding: 8px 14px;
  background: $wild-sand;

  :global {
    .DraftEditor-root {
      width: calc(100% - 34px);
    }
  }

  .editorFooter {
    width: 24px;
    justify-content: flex-end;
  }
}

.addNoteCard {
  border-radius: $small-border-radius;
  padding: 0;
  margin: 10px 18px;
  overflow: visible;
}

.addNoteWithAttachment {
  min-height: 165px !important;
}

.editorToolbar {
  visibility: hidden !important;
  display: none !important;
}

.isDisabled {
  color: currentColor;
  cursor: not-allowed;
  opacity: 0.2;
  text-decoration: none;
}

.expandText {
  color: $primary-color;
  cursor: pointer;
}

.expandTextContainer {
  padding: 0 0 5px 0;
  text-align: center;
  font-weight: 700;
}

.addTags {
  color: $primary-color;
  font-size: 10px;
  font-weight: 600;
  line-height: 25px;
  margin: 0 5px;
  display: contents;
  > svg {
    height: 10px;
    width: 10px;
    > path {
      fill: $primary-color;
    }
    > rect {
      stroke: $white;
    }
  }
}
.addTagsText {
  padding-left: 6px;
  cursor: pointer;
}

.isEditing {
  .editor {
    padding: 8px 14px;
    background: $wild-sand;
  }
}

.readMoreButton {
  cursor: pointer;
  color: $primary-color;
  font-weight: 700;
  font-size: 12px;
  text-align: center;
}

.circularLoader {
  display: flex;
  justify-content: center;
}

.select {
  width: 138px;
  height: 34px;
}

.summary {
  font-size: 14px;
  line-height: 30px;
  max-height: 70vh;
  overflow-y: scroll;
  white-space: pre-line;
  text-align: left;
}

.accepted {
  border: 2px dashed #22c55e;
  background-color: $light-green;
}

.fullScreenModal {
  width: 100%;
  height: 100%;
}

.fullScreenModalImage {
  max-height: calc(95vh - 215px);
  max-width: 100%;
}

.modalCard {
  margin-top: 15px;
  border: none !important;
  height: calc(95vh - 215px);
  width: 100%;
  align-items: center;
  display: flex;
}

.select {
  width: 160px;
  height: 34px;
  display: none;

  :global {
    .MuiInputBase-input {
      max-width: 114px;
    }
  }
}

.isFullScreenCard {
  cursor: default !important;
  span {
    font-size: 14px !important;
  }
}

.searchField {
  display: none;
  height: 34px;

  :global {
    .MuiInputBase-root {
      padding-left: 6px;
      height: 34px;
      input {
        line-height: 18px;
        height: 18px;
      }
    }
  }
}

.fieldOpen {
  display: block;
  :global {
    .MuiInputBase-root {
      background-color: $white;
    }
  }
}

.button {
  cursor: pointer;
  border-radius: $small-border-radius;
  width: 35px;
  height: 35px;
  box-sizing: border-box;
  border: none;
  padding: 7.5px;

  &:hover {
    background-color: $button-border-color;
  }
}

.buttonActive {
  background-color: $debtor-gray;
}

.searchButton {
  path {
    fill: $secondary-color;
  }
}

.startAdornment {
  margin-right: 3px;
}

.hiddenButton {
  display: none;
}

.filterCount {
  margin: 6px;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  color: $white;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  background-color: $primary-color;
  text-align: center;
}

.imageModalHeader {
  display: flex;
  padding: 34px 80px;
  font-size: 24px;
  color: $secondary-color;
  justify-content: space-between;
}

.summaryTitle {
  width: 300px;
}

.toolbarContainer {
  z-index: 100000;
}

.toolbarButtonWrapper {
  display: inline-block;
}

.toolbarButtonWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.toolbarButtonContainer {
  display: flex;
  align-items: center;
  gap: 20px;
}

.toolbarButton {
  background: transparent;
  color: $black;
  font-size: 18px;
  border: 0;
  vertical-align: bottom;
  height: 24px;
  width: 24px;
  padding: 0;
  cursor: pointer;

  &:hover,
  &:focus {
    background: #f3f3f3;
    outline: 0; /* reset for :focus */
  }

  svg {
    fill: $black;

    &.toolbarButtonAttachmentIcon {
      cursor: pointer !important;
      path {
        fill: $black;
      }
    }
  }
}

.toolbarButtonActive {
  background: $mercury;
  color: $black;

  svg {
    fill: $black;
  }
}

.toolbarStyles {
  left: 50%;
  transform: translate(-50%) scale(0);
  position: absolute;
  background: $white;
  box-shadow: 0 1px 3px 0 rgba(220, 220, 220, 1);
  box-sizing: border-box;
  z-index: 100000;
  padding: 16px 24px;

  &:after,
  &:before {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-color: rgba(255, 255, 255, 0);
    border-top-color: #ffffff;
    border-width: 4px;
    margin-left: -4px;
  }

  &:before {
    border-color: rgba(221, 221, 221, 0);
    border-top-color: #dddddd;
    border-width: 6px;
    margin-left: -6px;
  }
}

.public-DraftStyleDefault-block > div {
  margin: 0px;
}

.notesContainer {
  overflow: visible !important;
  padding: 0;
}

.notesCardContainer {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 124px);
}

.addNoteContainer {
  position: relative;

  .noteEditorEditor {
    :global {
      .DraftEditor-root {
        max-height: calc(100vh - 605px);
        overflow: auto;
      }
    }
  }
}

.addNoteContainerExpandIcon {
  position: absolute;
  top: 14px;
  right: 10px;
  border: none;
  width: 16px;
  height: 16px;
  z-index: 1;
}

.addNewNoteExpandedContainer,
.noteExpandedContainer {
  .toolbarContainer {
    &:not(.toolbarContainerReply) {
      position: relative;
    }
  }
}

.addNewNoteExpandedContainer {
  .toolbarContainer {
    position: relative;
  }

  .toolbarStyles {
    visibility: visible !important;
    transform: none !important;
    top: unset !important;
    left: 24px !important;
    right: 24px !important;
    bottom: 10px !important;
    border-radius: $main-border-radius;
    background: $light-gray;
    box-shadow: none;

    &:before,
    &:after {
      display: none;
    }
  }

  :global {
    .DraftEditor-root {
      margin-bottom: 65px;
    }
  }
}

.notesRepliesContainerList {
  flex: 1;
}

.noteExpandedContainer {
  height: 100%;
  display: flex;
  flex-direction: column;

  .editor {
    &.isExpanded {
      margin-bottom: 65px;
    }
  }

  .isEditing {
    .toolbarContainer {
      &:not(.toolbarContainerReply) {
        .toolbarStyles {
          visibility: visible !important;
          transform: none !important;
          top: unset !important;
          left: 14px !important;
          right: 14px !important;
          bottom: 10px !important;
          border-radius: $main-border-radius;
          background: $light-gray;
          box-shadow: none;

          &:before,
          &:after {
            display: none;
          }
        }
      }
    }
  }
}

.addNewNoteExpandedContainer {
  background: $wild-sand;
  padding-bottom: 14px;
  padding-top: 10px;

  .noteEditorEditor {
    margin: 0 16px;
    min-height: 300px;
    padding-bottom: 10px;
  }

  :global {
    .DraftEditor-root {
      max-height: calc(100vh - 240px);
      overflow: auto;
    }
  }
}

.addNoteContainerSmall {
  .noteEditorEditor {
    :global {
      .DraftEditor-root {
        max-height: 70px !important;
        overflow: auto;
      }
    }
  }
}

.addNoteContainerDisabled {
  cursor: not-allowed;
  opacity: 0.5;

  .addNoteCard {
    pointer-events: none;
  }

  .toolbarContainer {
    display: none;
  }
}

.actionsContainer {
  padding: 10px 7px;
  border-bottom: 1px solid $button-border-color;
  border-top: 1px solid $button-border-color;

  @media only screen and (min-width: 1800px) {
    padding: 10px 22px;
  }
}

.actionButtonContainer {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.actionButtonContainerFilterContainer {
  flex: 1;

  > div {
    padding: 0;

    > div {
      padding: 0;
    }
  }

  [class*='FilterContainer_clearAllButton'] {
    margin-left: 0;
  }

  [data-search='open'] [class*='FilterContainer_clearAllButton_'] {
    flex: 0 0 100%;
    order: 1;
  }

  [class*='FilterContainer_searchField_'] {
    &:not([class*='FilterContainer_searchFieldFocused_']) {
      max-width: 159px;
    }
    margin-right: 0;

    @media only screen and (min-width: 1800px) {
      &:not([class*='FilterContainer_searchFieldFocused_']) {
        max-width: 200px;
      }
      margin-right: 5px;
    }
  }

  [class*='FilterButton_filterButton_'] {
    height: 35px;
    min-width: 35px;
    padding: 7.5px;
  }

  [class*='FilterContainer_searchField_'] {
    :global {
      .MuiInput-root {
        height: 35px;
      }
    }
  }

  [class*='FilterContainer_searchButton'] {
    height: 35px;
    width: 35px;
    padding: 7.5px;
  }
}

.emptyContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.emptyContainerText {
  color: $secondary-color;
  font-size: 14px;
  line-height: 1.5;
}

.notesList {
  flex-grow: 1;
  padding-bottom: 5px;
  overflow-x: visible !important;
  overflow-y: auto !important;

  @include thin-scrollbar;
}

.infiniteScroll {
  overflow: visible !important;
  padding: 10px 7px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.actionsBar {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;

  &.commentActionsBar {
    margin: 10px 0 5px;
  }
}

.note {
  padding: 0;
  overflow: hidden;

  &:not(&.isExpandModal) {
    &:not(&.child) {
      cursor: pointer;
    }
  }

  &.isEditing {
    overflow: visible;
  }

  &.isReplyShown {
    cursor: default !important;
  }
}

.noteTopRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px 10px;
  background: $gray-background-color;
  border-radius: $main-border-radius $main-border-radius 0 0;
}

.grabbableTopRow {
  cursor: grab;
}

.callAgendaIcon > svg > path {
  transform: scale(0.862);
  transform-origin: center;
}

.noteBreadcrumbs {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.indexBreadCrumb {
  background-color: transparent !important;
  margin: 0 !important;
  padding: 4px 8px 4px 0 !important;
  cursor: default !important;
}

.noteBreadcrumbItem {
  display: inline-block;
  font-size: 10px;
  line-height: 14px;
  font-weight: 700;
  padding: 4px 8px;
  border-radius: $small-border-radius;
  color: $secondary-color;
  box-sizing: border-box;
  background-color: white;
  cursor: pointer;
  > svg {
    top: 0px;
    position: absolute;
    > path {
      fill: transparent;
    }
  }
}

.noteBreadcrumbItem:hover {
  display: inline-block;
  font-size: 10px;
  line-height: 14px;
  font-weight: 700;
  padding: 4px 8px;
  border-radius: $small-border-radius;
  color: $secondary-color;
  box-sizing: border-box;
  background-color: white;
  cursor: pointer;
  > svg {
    top: 0px;
    position: absolute;
    > path {
      fill: $black;
    }
  }
}

.noteBreadcrumbSeparator {
  margin: 0 5px;

  path {
    fill: $primary-color;
  }
}
.diligenceBreadCrumb {
  margin: 0 5px;
}

.noteTopRowActions {
  display: flex;
  gap: 4px;
}

.noteTopRowAction {
  padding: 6px;
  cursor: pointer;
  width: 28px !important;
  height: 28px !important;

  svg {
    width: 16px;
    height: 16px;
  }

  &.noteTopRowActionDisabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &.noteTopRowActionResolved {
    svg path:first-child {
      stroke: $success-green;
    }
  }

  &.noteTopRowActionPinned {
    svg {
      path {
        stroke: $gold;
      }

      fill: $gold;
    }
  }

  &.noteTopRowActionOnCallAgenda {
    svg {
      path {
        stroke: $primary-color;
      }

      fill: $primary-color;
    }
  }

  &.noteTopRowActionOnActionRequired {
    svg {
      path {
        stroke: $yellow-tag;
      }

      fill: $yellow-tag;
    }
  }

  &.noteTopRowActionMenu {
    padding: 4px;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  &:hover {
    background-color: $button-border-color;
    border-radius: $small-border-radius;
  }
}

.noteTopRowMenu {
  padding: 8px;
  box-shadow: 0 0 20px 0 rgba($black, 0.1);
  border-radius: $small-border-radius;

  li {
    padding: 8px 12px;
    font-size: 14px;
    line-height: 20px;
    min-width: 140px;
  }
}

.noteTopRowMenuItemDelete {
  color: $red-text;
}

.noteWrapper {
  padding: 8px 16px;
  display: flex;
  gap: 8px;
  max-width: 100%;
  overflow: hidden;
}

.withChildren {
  padding: 8px 16px 0 !important;
}

.noteAvatarWrapper {
  display: flex;
  align-items: flex-start;
  svg {
    width: 32px;
    height: 32px;
  }
}

.noteAvatarFlags {
  height: 32px;
  width: 32px;
  font-size: 12px;
  background-color: $light-red;
  svg {
    width: 24px;
    height: 24px;
  }
}

.notesAvatarYellowFlag {
  background-color: $yellow-background;
}

.noteAvatarLog {
  height: 32px;
  width: 32px;
  background-color: $light-grey;
  svg {
    width: 16px;
    height: 16px;
  }
}

.noteAvatar {
  height: 32px;
  width: 32px;
  font-size: 12px;
}

.noteContainer {
  display: flex;
  flex-direction: column;
  gap: 6px;
  flex: 1;
  max-width: 100%;
}

.noteContainerNoAvatar {
  gap: 0px !important;
}

.noteHeading {
  display: flex;
  gap: 8px;
  align-items: center;
}

.noteHeadingUserAndDate {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 5px;
  justify-content: space-between;
}

.dateNoAvatar {
  position: absolute !important;
  right: 15px !important;
  top: 50px !important;
}

.dateNoAvatarRight {
  flex-direction: row-reverse !important;
}

.noteHeadingUser {
  color: $secondary-color;
  font-size: 12px;
  line-height: 1;
}

.noteHeadingDate {
  color: $silver;
  font-size: 10px;
  line-height: 1;
}

.noteBody {
  color: $secondary-color;
  font-size: 15px;
  line-height: 22px;

  span > p {
    margin: 0;
    padding: 0;
  }

  :global {
    .public-DraftStyleDefault-ul,
    .public-DraftStyleDefault-ol {
      margin: 0;
    }
  }

  .readOnly {
    &:not(&.isExpanded) {
      :global {
        .DraftEditor-root {
          overflow: hidden;
          max-height: 66px;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
        }
      }
    }
  }
}

.noteEditor {
  .actionsBar {
    justify-content: space-between;
  }
}

.editorFooter {
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.addAttachmentButton {
  cursor: pointer !important;
  height: 24px;

  .div {
    height: 20px;
    width: 20px;
  }
}

.selected {
  border-color: $primary-color !important;
}

.isExpandModal {
  height: 100%;
  border: none !important;

  .noteBody {
    font-size: 15px;
  }

  &.isEditing {
    .noteWrapperParent {
      .noteBody {
        border-radius: $small-border-radius;
        border: 1px solid $gallery;

        .editor {
          background: $white;
          min-height: 200px;
          max-height: calc(70vh - 355px);
          overflow-y: auto;
        }

        background: $white;

        .commentActionsBar {
          margin: 10px 14px;
        }
      }
    }
  }

  .noteWrapperParent {
    padding: 8px 32px 8px;
    background: $wild-sand;
    min-height: 290px;
    max-height: calc(70vh - 200px);

    .readOnly {
      max-height: calc(70vh - 250px);
      overflow-y: auto;
    }
  }

  .noteTopRow {
    padding: 20px 32px 14px;
    background: $wild-sand;
  }

  .child {
    padding: 0 16px 8px;

    .noteWrapper {
      padding-top: 16px;
    }

    &:before {
      width: calc(100% - 32px);
      margin-left: 16px;
    }

    &:first-of-type {
      &:before {
        display: none;
      }
    }
  }

  .noteCardContent {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .childrenContainer {
    display: flex;
    flex: 1;
  }

  .childrenContainerContent {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .childrenContainerList {
    flex: 1;
    max-height: calc(100vh - 490px);
    overflow: scroll;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
  }

  .childrenContainerListEmpty {
    overflow: visible;
  }

  .childrenContainerListFlag {
    max-height: calc(100vh - 290px) !important;
  }

  .childrenContainerListFlagWithTable {
    max-height: calc(100vh - 602px) !important;
  }

  .expandModalReply {
    display: flex;
    .commentEditor {
      width: calc(100% - 74px) !important;
    }
    .actionsBar {
      align-items: end;
    }
    .commentActionsBar {
      margin: 0 !important;
      margin-left: 10px !important;
    }
  }

  .commentContainer {
    margin: 0;
    padding: 14px 32px;
    border-top: 1px solid $gallery;
    border-radius: 0;

    :global {
      .DraftEditor-root {
        max-height: 100px !important;
        overflow: auto;
      }
    }
  }
}

.noteRepliesLabel {
  padding: 20px 32px;
  color: var(--Black, #1a202c);
  font-size: 16px;
  font-weight: 800;
  line-height: 1.5;
}

.childrenCommentItems {
  &.childrenCommentItemsFlag {
    flex: 1;
    overflow: auto;
  }
  &.childrenCommentItems {
    flex: 1;
    overflow: auto;
  }
}

.expandModalReplyForce {
  display: flex;

  .commentEditor {
    width: calc(100% - 74px) !important;
  }

  .actionsBar {
    align-items: end;
  }

  .commentActionsBar {
    margin: 0 !important;
    margin-left: 10px !important;
  }
}

.isDragging {
  border: 1px solid $primary-color !important;
}

.filterMenuPopoverPaper {
  width: 250px;
  padding: 20px;
}

.sortButton {
  margin-left: 4px;
}

.sortMenuPopoverPaper {
  width: 175px;
  padding: 5px;
}

.sortMenuItem {
  padding: 10px 16px;
  font-size: 12px;
  line-height: 15px;
  color: $secondary-color;

  &:hover {
    background-color: $gallery;
  }

  &.sortMenuItemSelected {
    background-color: $button-border-color;

    &:hover {
      background-color: $gallery;
    }
  }
}

.toolbarStaticStyles {
  margin: 10px 0 5px;
  padding: 0;
  background: $wild-sand;
  display: none;
  border-radius: $small-border-radius;

  .toolbarButtonContainer {
    gap: 8px;
    padding: 5px;

    .toolbarButton {
      width: 22px;
      height: 22px;
      padding: 2px;

      svg {
        width: 18px;
        height: 18px;
      }
    }
  }
}

.toolbarContainerStaticVisible {
  .toolbarStaticStyles {
    display: block;
  }
}

.toolbarContainerStaticWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
