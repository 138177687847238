@import "../../assets/styles/colors";
@import "../../assets/styles/variables";
@import "../../assets/styles/mixins";

.table {
  overflow: auto;

  tr {
    td, th {
      &:nth-child(1) {
        width: 100px;
      }
      &:nth-child(2) {
        width: 35%;
      }
    }
  }
}

.fullScreenModal {
  overflow-y: auto;

  > div {
    padding: 20px;
  }

  .table {
    max-height: calc(95vh - 179px) !important;
    margin-left: -10px;

    tbody {
    max-height: calc(95vh - 255px) !important
    }
  }
}

.activeToolbar {
  margin-right: -8px;
  overflow-x: auto !important;
  display: inline-flex !important;
  width: calc(100% + 8px) !important;
  flex-shrink: 0;

  > div {
    flex-shrink: 0;

    &:nth-child(1) {
      flex: 5;
    }

    &:nth-child(2) {
      flex: 1;
    }
  }
}

.chartContainer {
  height: calc(100vh - 512px);
  min-height: 400px;
}

.fullScreen {
  height: calc(100vh - 230px);
}

.selectField {
  width: 105px;
  height: 36px;

  @include select-field-with-top-label;
}


.header {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  gap: 20px;
}

.title {
  font-size: 18px;
  font-weight: 800;
}

.editLink {
  cursor: pointer;
  color: $primary-color;
  font-size: 12px;
  font-weight: 600;
  line-height: 24px;
  justify-content: center;
  align-items: center;
  display: flex;
}




.mappingContainerContent {
  height: calc(95vh - 118px);
}

.mappingContainerTitle {
  padding: 14px 0;
  text-align: left;
  font-size: 24px;
  line-height: 40px;
}

.mappingContainerTitleText {
  margin-left: 25px;
}

