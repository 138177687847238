@import '../../assets/styles/colors';
@import '../../assets/styles/variables';

.table {
  thead {
    tr {
      margin: -8px 0;
    }
  }

  tbody {
    position: relative;
    max-height: 240px !important;

    tr {
      td {
        &.totals {
          background: $wild-sand;
          font-weight: 700;
        }
      }
    }
  }
}

.arTable {
  tr {
    td, th {
      &:nth-child(1) {
        width: 182px !important;
      }

      &:nth-child(2) {
        width: 50px;
      }

      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6) {
        width: auto !important;
      }

      &:nth-child(7) {
        width: 465px;
      }

      &:nth-child(8) {
        width: 158px;
      }
    }
  }
}

.apTable {
  tr {
    td, th {
      &:nth-child(1) {
        width: 212px !important;
      }

      &:nth-child(2) {
        width: 50px;
      }

      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6) {
        width: auto !important;
      }

      &:nth-child(7) {
        width: 320px;
      }

      &:nth-child(8) {
        width: 96px;
      }
    }
  }
}

.activeToolbar {
  padding: 0 32px 0 24px;
  order: 1;
}

.arActiveToolbar {
  display: flex;
  > div {
    &:nth-child(1) {
      width: 248px;
      padding-left: 2px;
    }

    &:nth-child(2), &:nth-child(3), &:nth-child(4), &:nth-child(5) {
      flex: 1;
    }

    &:nth-child(6) {
      width: 465px;
    }

    &:nth-child(7) {
      width: 66px;
    }
  }
}

.apActiveToolbar {
  display: flex;
  > div {
    &:nth-child(1) {
      width: 278px;
      padding-left: 2px;
    }

    &:nth-child(2), &:nth-child(3), &:nth-child(4), &:nth-child(5) {
      flex: 1;
    }

    &:nth-child(6) {
      width: 320px;
    }

    &:nth-child(7) {
      width: 90px;
    }
  }
}
