@import '../../assets/styles/colors';
@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.table {
  border-radius: 10px;
  table tbody {
    max-height: 50vh !important;
  }

  tr {
    td {
      :global {
        .MuiSelect-select {
          padding: 2px 7px;
        }
        .MuiSelect-icon {
          top: 4px;
          padding: 2px 5px;
        }
        .MuiCheckbox-root {
          padding: 0;
        }
        .MuiFormControlLabel-root {
          margin-left: 0;
        }
      }
    }

    &.newRow {
      background-color: $mabel !important;
    }
  }
}

.fullTable {
  td,
  th {
    &:nth-child(1) {
      width: 15%;
    }

    &:nth-child(3),
    &:nth-child(5),
    &:nth-child(6),
    &:nth-child(9) {
      width: 110px;
    }
    &:nth-child(12) {
      width: 70px;
    }
  }
}

.hiddenColsTable {
  td,
  th {
    &:nth-child(1) {
      width: 15%;
    }

    &:nth-child(4),
    &:nth-child(7) {
      width: 110px;
    }
    &:nth-child(10) {
      width: 70px;
    }
  }
}

.skuTypesList {
  width: 97%;
}

.distributionTitle {
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
  color: $secondary-color;
}

.distributionOptions {
  width: 120px;
  height: 36px;
}

.distributionOptionsSmall {
  width: 100px;
  height: 36px;
}

.activeToolbar {
  order: 1;

  > div {
    width: 13%;

    &:nth-child(1) {
      width: 22%;
    }

    &:nth-child(6),
    &:nth-child(9) {
      width: 110px;
    }
    &:nth-child(12) {
      width: 70px;
    }
  }
}

.updateWarning {
  font-size: 14px;
  line-height: 25px;
  height: 25px;
  width: 190px;
  text-align: center;
}

.titleContainer {
  display: flex;
}

.warningIcon {
  width: 24px;
  height: 24px;
  margin-left: 10px;
}

.asOfLabel {
  font-size: 14px;
  line-height: 1;
  font-weight: 500;
}

.clientUserTitle {
  line-height: normal;
}
.editLink {
  font-size: 12px;
  line-height: 23px;
  margin-left: 10px;
}
