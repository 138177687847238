@import "../../assets/styles/colors";
@import "../../assets/styles/variables";

.passThroughsTable {
  tbody {
    max-height: calc(100vh - 365px) !important;
  }
  tr {
    height: 38px;

    td, th {
      &:nth-child(1) {
        width: 30%;
      }

      &:nth-child(2) {
        width: 20%;
      }

      &:nth-child(5) {
        width: 200px;
      }
    }
  }
}

.activeToolbar {
  order: 2;

  > div {
    &:nth-child(1) {
      width: 30%;
    }

    &:nth-child(2) {
      width: 20%;
    }
  }
}

.passThroughsTableClientName {
  color: $secondary-color;
  font-weight: bold;
}

.passThroughsTableStatus {
  font-size: 12px;
  line-height: 16px;
  padding: 3px 7px;
  border-radius: $small-border-radius;
}

.passThroughsTableStatusUnsent {
  background: $red-background;
  color: $red-text !important;

  :hover {
    background: $red-background;
    color: $red-text;
  }
}

.passThroughsTableStatusPending {
  background: $yellow-background;
  color: $yellow-text !important;

  :hover {
    background: $yellow-background;
    color: $yellow-text;
  }
}

.passThroughsTableStatusSent {
  background: $green-background;
  color: $green-text !important;

  :hover {
    background: $green-background;
    color: $green-text;
  }
}

.priceAdornment {
  color: $secondary-color;

}

.selectedItems {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 600;
  color: $secondary-color;
}
