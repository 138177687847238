@import '../../assets/styles/colors';
@import '../../assets/styles/variables';

.table {
  tr {
    height: 30px;
    background: $white;
    td, th {
      &:nth-child(1) {
        width: 3%;
      }

      &:nth-child(2) {
        width: 22%;
      }
    }
  }

  thead {
    tr {
      th {
        padding: 2px 5px 3px;
        line-height: 28px;

        &:last-of-type {
          padding-right: 0;
        }
      }
    }
  }

  tbody {
    position: relative;

    .parentRow {
      position: sticky;
      top: 0;
      z-index: 1;
    }
  }

  tfoot {
    tr {
      td {
        &:nth-child(1) {
          width: 25%;
        }

        &:nth-child(2) {
          width: auto;
        }
      }
    }
  }
}

.activeToolbar {
  order: 2;

  > div {
    width: 15%;

    &:nth-child(1) {
      width: 25%;
    }
  }
}

.fullScreenModal {
  overflow-y: auto;
  table {
    tbody {
      // Padding + card header + table header + table footer
      max-height: calc(95vh - 224px) !important;
    }
  }
}
