@import '../../assets/styles/colors';
@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.recordDateTitle {
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
  color: $secondary-color;
}

.selectField {
  width: 150px;
  height: 36px;

  @include select-field-with-top-label;
}

h2 {
  color: $black !important;
}

.table {

  margin-left: 24px;

  tbody {
    max-height: calc(100vh - 430px) !important;
  }

  tr {
    td,
    th {
      &:nth-child(1) {
        width: 29%;
      }

      &:nth-child(2) {
        width: 7%;
      }

      &:nth-child(3) {
        width: 20%;
      }

      &:nth-child(4) {
        width: 20%;
      }

      &:nth-child(5) {
        width: 24%;
      }
    }
  }}

.activeToolbar {
  order: 1;

  > div {
    width: 13%;

    &:nth-child(1) {
      width: 15%;
    }

    &:nth-child(2) {
      width: 8%;
    }

    &:nth-child(3) {
      width: 6%;
    }

    &:nth-child(9) {
      width: 6%;
    }
  }
}

percentMatch {
  display: inline-block;
  font-size: $main-font-size;
  line-height: 14px;
  padding: 3px 7px;
  border-radius: $small-border-radius;
  color: $white;
  cursor: default;
}

.percentMatchWithPrediction {
  display: inline-block;
  font-size: $main-font-size;
  line-height: 14px;
  padding: 3px 7px;
  border-radius: $small-border-radius;
  color: $white;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  vertical-align: middle;
}

.percentMatchNew {
  background: $cerulean !important;
}


.percentMatchHigh {
  background: $green-background;
  color: $green-text !important;

  :hover {
    background: $green-background;
    color: $green-text;
  }
}

.percentMatchHighActive {
  background: $green-text;
  color: $white !important;


  :hover {
    background: $green-text;
  }
}

.disablePrediction{
  cursor: default;
}

.newValue {
  background-color: $blue-background !important;
  color: $primary-color !important;
}

.taggingField {
  border-radius: $small-border-radius;
  height: 26px;
}

.chip {
  color: $black;
  cursor: pointer;
  max-width: 39%;
  max-height: 80%;
  margin-right: 1px;
  margin-left: 3px;
  margin-top: 2px;
  margin-bottom: 1px;
  font-size: 11px !important;
}

.selectedItems {
  font-size: 16px;
  font-weight: 500;
}