@import "../../assets/styles/colors.scss";
@import "../../assets/styles/variables.scss";

.activeToolbar {
  display: flex;
  flex: 1;
  align-items: center;
  min-height: 48px;
  left: 0 !important;

  :global {
    .MuiFormControl-root {
      align-items: center;
      height: 24px;
      left: 0px;

      > div {
        width: 97%;
        height: 24px;

        &.MuiAutocomplete-inputRoot {
          padding-top: 0 !important;
          padding-bottom: 0 !important;

          &:before {
            top: 5px;
          }

          .MuiAutocomplete-input {
            padding: 2px 5px !important;
          }
        }

        @media only screen and (max-width: 1279px) {
          width: 110px;
          max-width: 110px;
        }
      }

      .MuiSelect-select {
        padding: 2px 7px;
      }

      .MuiSelect-icon {
        width: 18px;
        height: 18px;
        top: 3px;
        padding: 2px 5px;
        line-height: 14px;
        display: inline-block;
      }
    }

    .MuiAutocomplete-root, .MuiSelect-root, .MuiTextField-root .MuiInputBase-root {
      background: $white;
    }

    .MuiAutocomplete-root {
      .MuiAutocomplete-inputRoot {
        margin-right: -30px;
        border: 1.5px solid $wild-sand;
        border-radius: $small-border-radius;
      }
    }
  }

  > div {
    color: $secondary-color;
    font-weight: 700;
    font-size: 14px;
    line-height: 48px;
    text-align: left;
    height: 100%;

    &:not(&.activeToolbarItemsCount) {
      + div {
        border-left: 1px solid $button-border-color;
      }
    }

    &.activeToolbarItemsCount {
      padding-left: 10px;
      font-size: 18px;
      font-weight: 500;
    }
  }
}

