@import "../../assets/styles/colors";
@import "../../assets/styles/variables";
@import "../../assets/styles/mixins";

.selectField {
  width: 135px;
  height: 36px;

  @include select-field-with-top-label;
}

.table {
  tr {
    td,
    th {
      &:nth-child(1) {
        width: 18%;
      }
      &:nth-child(8), &:nth-child(9) {
        width: 8%;
      }
      &:nth-child(10) {
        width: 6%;
      }


      &:nth-child(10) {
        text-align: right !important;
        span {
          right: 10px;
        }
      }
    }
  }
}

.activeToolbar {
  order: 4;
  width: 100%;

  > div {
    width: 10%;

    &:nth-child(1) {
      width: 18%;
    }

    &:nth-child(8), &:nth-child(9) {
      width: 8%;
    }
    &:nth-child(10) {
      width: 6%;
    }
  }
}

.fullScreenModal {
  overflow-y: auto;

  table {
    tbody {
      // Padding + card header + table header + table footer
      max-height: calc(95vh - 368px) !important;
    }
  }

  > div {
    padding: 20px;
  }
}
