@import '../../assets/styles/colors';
@import '../../assets/styles/variables';

.wiresDebtorsWrapper {
  padding: 0 !important;
}

.wiresDebtorsTitle {
  color: $primary-color;
  font-size: 18px;
  line-height: 20px;
  font-weight: bold;
}

.cardContainer {
  margin-bottom: 24px;
}

.wiresDebtorsTable {
  tbody {
    max-height: 50vh !important;

    @media only screen and (max-height: 800px) {
      max-height: 60vh !important;
    }

    tr {
      &.validRow {
        background: $valid-row-green;
      }
    }
  }

  tr {
    height: 33px;

    cursor: pointer;
    margin-top: 4px;
    margin-bottom: 4px;
    background: $white;

    td,
    th {
      &:nth-child(1) {
        &:before {
          border-left: none;
        }

        width: 7%;
      }

      &:nth-child(2),
      &:nth-child(4),
      &:nth-child(5) {
        width: 19%;
      }

      &:nth-child(3) {
        width: 15%;
      }

      &:nth-child(6),
      &:nth-child(7) {
        width: 9%;
      }

      &:nth-child(8) {
        width: 60px;
      }
    }
  }

  tbody {
    td {
      :global {
        .MuiFormControl-root {
          > div {
            width: 100% !important;
            max-width: none !important;
          }
        }
      }
    }
  }
}

.wiresReconciliationTable {
  tr {
    td,
    th {
      &:nth-child(1) {
        width: 200px;
      }

      &:nth-child(2) {
        width: 250px;
      }

      &:nth-child(3) {
        width: 600px;
      }
    }
  }

  tbody {
    tr {
      background-color: $white;

      &.validRow {
        background: $green-background;
        color: $black;
        font-weight: 700;

        span {
          color: $black;
        }
      }

      &.rowInvalid {
        background: $red-background !important;
        color: $black;
        font-weight: 700;

        span {
          color: $black;
        }
      }
    }

    td {
      padding: 10px;

      > div {
        width: 100% !important;
        max-width: none !important;
      }
    }
  }
}

.wiresDebtorsTable,
.wiresReconciliationTable {
  tbody {
    tr {
      background: $white;

      &.withCheckbox {
        td,
        th {
          &:nth-child(1) {
            width: 40px;
          }
        }
      }

      &.activeRow {
        background: $white;
      }

      .actionBtn {
        display: none;
        cursor: pointer;
      }

      .negativeIcon {
        path {
          fill: $red;
        }
      }

      &:hover {
        .actionBtn {
          display: inline-flex;
        }
      }
    }
  }
}

.actionBtn {
  cursor: pointer;
}

.wiresDebtorsMemo {
  max-width: 260px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.predictionCard {
  display: inline-block;
  font-size: $main-font-size;
  font-weight: 600;
  line-height: 14px;
  padding: 3px 7px;
  border-radius: $small-border-radius;
  color: $green-text;
  background-color: $green-background;
  cursor: pointer;
}

.predictionCardNewValue {
  background-color: $blue-background;
  color: $primary-color;
}

.activeToolbar {
  order: 1;

  &.activeToolbarDeleted {
    order: 2;
  }

  &.activeToolbarNegative {
    order: 3;

    > div {
      &:nth-child(4) {
        color: $light-red;
      }
    }
  }

  > div {
    &:nth-child(1) {
      width: 87%;
    }

    &:nth-child(2) {
      width: 8%;
    }
  }
}

.sectionHeader {
  color: $dark-blue !important;
  margin: 15px 0 10px !important;
}

.columnWrapper {
  position: relative;
  align-items: center;
  display: flex;
}

.negativeLabel {
  position: absolute;
  left: 10px;
  display: inline-block;
  padding: 0 10px;
  color: $gothic;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  background: $white;
  border-radius: 11px;
  border: 1px solid $gothic;
}

.actionButton {
  padding: 15px 10px;
}

.warningIcon {
  width: 16px;
  height: 16px;
  margin-right:7px;
}
