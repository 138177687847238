@import '../../assets/styles/colors';

.table {
  tr {
    td, th {
      &:nth-child(1) {
        width: 10%;
      }

      &:nth-child(2) {
        width: 25%;
      }

      &:nth-child(3) {
        width: 25%;
      }

      &:nth-child(4) {
        width: 20%;
      }
      &:nth-child(5) {
        width: 20%;
      }
    }
  }
}

.activeToolbar {
  order: 3;

  > div {
    &:nth-child(1) {
      width: 80%;
    }

    &:nth-child(2) {
      width: 20%;
    }
  }
}

.aggregationTableType {
  color: $secondary-color;
  font-weight: bold;
  cursor: pointer;
}

.linkIcon {
  margin-left: 5px;
  vertical-align: middle;
  width: 12px;
  height: 12px;
}
