@import '../../assets/styles/colors';
@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.table {
  thead {
    tr {
      &:first-of-type {
        th {
          border-bottom: none;
        }
      }
    }
  }

  tbody {
    max-height: calc(100vh - 480px) !important;

    tr {
      td {

        &:nth-child(2) {
          padding-left: 20px;
        }

        &.totals {
          padding-right: 0px !important;

          > p {
            width: 100%;
            display: block;
            background: $catskill-white;
            font-weight: 700;
            color: $black !important;
            text-align: right;
            margin: 0px;
            padding: 2px 10px 2px 10px;
          }
        }
      }
    }
  }

  tfoot {
    tr {
      td {
        padding-right: 0 !important;
        padding-left: 0 !important;

        p {
          width: 100%;
          display: block;
          font-weight: 700 !important;
          color: $white !important;
          text-align: right;
          margin: 0;
          padding: 2px 10px 2px 10px;
        }
      }
    }
  }

  tr {
    td,
    th {
      &:nth-child(1) {
        width: 20%;
      }
      &:nth-child(6) {
        width: 10%;
      }
    }
  }
}

.invisible {
  color: $catskill-white;
}

.activeToolbar {
  > div {
    &:nth-child(1) {
      width: 62%;
    }

    &:nth-child(2),
    &:nth-child(4) {
      width: 14%;
    }

    &:nth-child(3) {
      width: 10%;
    }
  }
}

.fullScreenModal {
  overflow-y: auto;

  :global {
    .MuiTableContainer-root {
      max-height: calc(95vh - 60px);
    }
  }

  table {
    tbody {
      // Padding + card header + table header + table footer + new filters
      max-height: calc(95vh - 295px) !important;
    }
  }
}

.textField {
  width: 80px;

  @include text-field-with-top-label;
}
