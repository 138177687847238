@import '../../assets/styles/colors';
@import '../../assets/styles/variables';

.recordDateWrapper {
  margin-right: 20px;
}

.recordDateTitle {
  font-size: 13px;
  color: $blue-wale;
}

.recordDateList {
   margin-left: 20px;
   width: 110px;
   text-align: left;

   :global {
     .MuiSelect-select {
       padding: 4px 14px;
     }

     .MuiSelect-icon {
       top: 3px;
     }
   }
 }

.table {
  tr {
    td, th {
      &:nth-child(1) {
        width: 16%;
      }

      &:nth-child(2) {
        width: 10%;
      }

      &:nth-child(3) {
        width: 10%;
      }
    }
  }

  tbody {
    position: relative;

    tr {
      td {
        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6),
        &:nth-child(7),
        &:nth-child(9) {
          white-space: pre-wrap;
        }

        &.totals {
          background: $wild-sand;
          font-weight: 700;
        }
      }
    }

    .parentRow {
      position: sticky;
      top: 0;
      z-index: 1;
    }
  }

  tfoot {
    td {
      background: $wild-sand;
    }
  }
}

.activeToolbar {
  order: 1;

  > div {
    width: 16%;

    &:nth-child(1) {
      width: 16%;
    }

    &:nth-child(2), &:nth-child(3) {
      width: 10%;
    }
  }
}

.expandIcon {
  margin: 0;
  padding: 0;
  position: static;
}

.changedValue {
  white-space: nowrap;
}

.fullScreenModal {
  overflow-y: auto;
  table {
    tbody {
      // Padding + card header + table header + table footer + new filters
      max-height: calc(95vh - 284px) !important;
    }
  }
}
