@import '../../assets/styles/colors';
@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

  
  .table {
    display: block;

  
    table thead {
      max-width: calc(100vw - 551px);
      display: block;
      overflow-y: auto;
      table-layout: unset;
      margin: 0;

      @media only screen and (min-width: 1800px) {
        max-width: calc(100vw - 651px);
      }
  
      // Hide scrollbar
      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
  
      tr {
        display: table;
        width: 100%;
        table-layout: fixed;
        border-spacing: 0;
      }
    }

  tbody {
    max-width: calc(100vw - 551px);
    @media only screen and (min-width: 1800px) {
      max-width: calc(100vw - 651px);
    }
    max-height: calc(85vh - 185px);
    border-collapse: separate !important;
    > div > div {
      overflow: inherit !important; //allows for sticky left columns
    }
    tr {
      &.activeRow {
        background: $dropdown-selected-color;
      }

      td {
        :global {
          .MuiFormControl-root {
            height: auto !important;

            > div {
              height: auto !important;

              &:focus {
                height: auto !important;
              }
            }
          }
        }
      }
    }
  }
  tr {
    td,
    th {
      &:nth-child(1) {
        padding: 0;
        position: sticky !important;
        left: 0;
        width: 0px;
        background: $white;
        border: none !important;
        z-index: 2;
        overflow: visible;

        &.withExpandIcon {
          z-index: 3;
        }
      }
      &:nth-child(2) {
        padding-left: 15px;
        position: sticky !important;
        background-color: inherit !important;
        left: 0px;
        z-index: 1;
        min-width: 225px;
        width: 225px;
        text-align: left;
        //box shadow on the right
        box-shadow: 7px 20px 20px 0 rgba(0, 0, 0, 0.2);
      }

      &:nth-child(n + 3) {
        min-width: 175px;
        width: 175px;
        padding-top: 0;
        padding-bottom: 0;


        &.blueColumn {
          background: $slate-gray !important;
          color: $white;
        }
      }
    }

    &.headerRow {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      background: $catskill-white;

      td {
        border-top: 1px solid $cadet-blue;
        border-bottom: 1px solid $cadet-blue;
      }

      &.headerRowThinBorder {
        td {
          border-bottom: none;
        }
      }
    }

    &.bottomRow {
      font-weight: 600 !important;
      font-size: 12px;
      line-height: 16px;
      background: $slate-gray !important;
      color: $white;

      &:hover {
        color: $slate-gray !important;
      }
    }

  }

  :global {
    tr {
      &.activeRow {
        td {
          &:nth-child(1) {
            background: $white !important;
          }

          > div {
            color: $tundora !important;
          }
        }
      }
    }
  }
}

.activeToolbar {
  overflow-x: auto !important;
  display: inline-flex !important;
  width: 100% !important;
  flex-shrink: 0;

  &::-webkit-scrollbar {
    display: none;
  }

  > div {
    flex-shrink: 0;

    &:nth-child(1) {
      position: sticky !important;
      background-color: white !important;
      left: 0;
      width: 225px !important;
      text-align: left;
      //box shadow on the right
      box-shadow: 7px 20px 20px 0 rgba(0, 0, 0, 0.2);
    }

    &:not(:first-child) {
      width: 175px !important;
    }
  }
}

.tableHead {
  background-color: $row-background-color;
}

.accountHeader {
  font-weight: 700 !important;
  font-size: 12px !important;
  line-height: 16px;
  color: $secondary-color;
}

.amountHolder {
  font-size: 12px;
  line-height: 26px;
  position: relative;
}

.fullScreenModal {
  height: calc(95vh);
  overflow-y: auto;

  :global {
    .MuiTableContainer-root {
      // Padding + card header
      max-height: calc(95vh - 22px) !important;
      max-width: 100% !important;
    }
  }

  table {
    tbody {
      // Padding + card header + table header + new filters
      max-height: calc(95vh - 185px) !important;
    }
  }

  .table {
    width: 110%;

    table {
      thead, tbody {
        max-width: 90vw;
      }
    }
  }
}



.editLink {
  font-size: 12px;
  line-height: 24px;
}

.selectField {
  width: 104px;
  height: 36px;

  @include select-field-with-top-label;

}

.lineItemsField {
  width: 200px;
  height: 36px;

  @include select-field-with-top-label;

}


.titleContainer{
  width: 200px !important;
}

.noProcessedCapTable{
  font-size: 18px;
  font-weight: 600;
  color: $tundora;
}

.editLink {
  font-size: 12px;
  line-height: 24px;
  margin-left: 10px;
}