@import "../../assets/styles/colors";
@import "../../assets/styles/variables.scss";

.wrapper {
  align-items: center;
}

.title {
  font-size: $main-font-size;
  line-height: 24px;
  font-weight: 400;
  text-transform: unset;
  background-color: $white !important;
  border: 1px solid $input-border-color;
  color: $slate-gray;
  border-radius: $small-border-radius;
  padding: 8px 24px 8px 8px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  >span{
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.multiSelectTitle {
  max-width: 100%;
  >span {
    max-width: calc(100% - 30px);
  }
}

.open {
  border: 1px solid $primary-color;
}

.linkIcon {
  padding-left: 10px;
}

.titleIcon {
  display: flex;
  padding: 0px 7px;
}

.rotate {
  transform: rotate(180deg);
}



.fileName {
  display: flex;
  align-items: center;
  font-size: $main-font-size;
  line-height: 20px;
  height: 20px;

  svg {
    margin-right: 8px;
  }
}

.fileNameWrapper {
  overflow: hidden;
  height: 20px;
  display: flex;
  align-items: center;
}

.fileNameWrapperLink {
  color: $primary-color;
  cursor: pointer;

  .fileNameLabel {
    max-width: calc(100% - 19px);
  }
}

.fileNameLabel {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  font-weight: 600;
}

.fileNameLinkIcon {
  width: 14px;
  margin-left: 5px;
  margin-right: 0 !important;

  path {
    stroke: $primary-color;
  }
}

.fileNameIcon{
  margin-right: 5px;
}

.popover {
  box-shadow: 0 0 8px rgba(0, 142, 221, 0.2);
  border-radius: $main-border-radius;
  background: $white;
}

.filesList {
  min-width: 344px;
  max-height:58vh;
  padding: 4px 0;
  [class*="MuiTreeItem-iconContainer"] {
    display: none;
  }
  [class*="MuiTreeItem-label"] {
    padding: 8px 12px;
    font-size: $main-font-size;
    line-height: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  [class*="MuiTreeItem-group"] {
    margin-left: 0;
    [class*="MuiTreeItem-label"] {
      padding-left: 30px;
    }
  }
}

.label {
  &:hover {
   border-color: $primary-color;
  }
}

.content {
  padding: 0;

}

.selected {
  .content {
    background: $gray-background-color !important;
  }
}
.group {
  max-height: 25vh;
  overflow-y: scroll;
}

.icon {
  width: 20px;
  height: 20px;
  display: flex;
  padding: 5px;
  margin-left: 10px;
  border-radius: 50%;
  cursor: pointer;
}

.clearIcon {
  background: $red-background;

  > svg path {
    fill: $red-text;
  }
}

.addIcon {
  background: $green-background;

  > svg {
    width: 10px;
    height: 10px;
    fill: $green-text;
  }
}
