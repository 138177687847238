@import "../../assets/styles/colors";
@import "../../assets/styles/variables";
@import "../BBCFileSelect/BBCFileSelect.module.scss";

.linksBlockItem {
  cursor: pointer;
  display: inline-block;
  font-size: $main-font-size;
  line-height: 20px;
  color: $primary-color;
  font-weight: 500;
}


.selectedFile{
  background-color: $green-background;
  border: $success-green 1px solid;
  border-radius: $small-border-radius;
  padding: 10px 0px;
  text-align: left;
  margin: 5px 0px;
}

.error{
  background-color: $red-background ;
  border: $red 1px solid;

}

.fileName{
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  font-weight: 600;
  line-height: 15px;

}

.nameContainer{
 overflow: hidden;
 text-overflow: ellipsis;
 gap: 0px;
}

.sheetName{
  font-size: 10px;
}


.tooltipError {
  background-color: $red-background;
  color: black;
  // font-weight: 600;
  padding: 10px;
}

.errorTitle {
  color: $red-text;
  margin-bottom: 5px;;
}

.tooltipArrow {
  color:  $red-background;

  &::before {
    background-color: $red-background;
}
}

