@import '../../assets/styles/colors.scss';
@import '../../assets/styles/variables';

.outgoingWireTable {
  tbody {
    max-height: calc(100vh - 365px) !important;
  }
  tr {
    height: 38px;

    td,
    th {
      &:last-child {
        width: 150px;
      }
    }
  }
}
.outgoingWireTableParticipantPage {
  tbody {
    max-height: 350px !important;
  }
  tr {
    height: 24px;

  }
}

.activeToolbar {
  order: 1;

  > div {
    &:nth-child(1) {
      width: 58%;
    }
  }
}

.activeToolbarParticipant {
  order: 1;

  > div {
    &:nth-child(1) {
      width: calc(65vw) !important;
    }
  }
}
