@import "../../assets/styles/colors";
@import "../../assets/styles/variables";
@import "../../assets/styles/mixins";

.table {
  overflow: auto;

  tr {
    td, th {
      &:nth-child(2) {
        width: 15%;
      }

      &:nth-child(3), &:nth-child(4) {
        width: 25%;
      }
    }
  }
}

.fullScreenModal {
  overflow-y: auto;

  > div {
    padding: 20px;
  }
}

.activeToolbar {
  margin-right: -8px;
  overflow-x: auto !important;
  display: inline-flex !important;
  width: calc(100% + 8px) !important;
  flex-shrink: 0;

  > div {

    &:nth-child(1) {
      width: 75%;
    }

    &:nth-child(2) {
      width: 25%;
    }
  }
}

