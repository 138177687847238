@import '../../assets/styles/colors';
@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.table {
  tbody {
    max-height: calc(100vh - 365px) !important;
  }
  tr {
    height: 32px;

    td,
    th {
      &:nth-child(1) {
        width: 75px;
      }
      &:nth-child(5) {
        width: 75px;
      }
    }
  }
}

.headerContainer {
  padding-top: 2px;
  tr {
    height: 32px;

    td,
    th {
      font-size: 11px !important;

      &:nth-child(1) {
        width: 75px;
      }
    }
  }
}

.status {
  display: inline-block;
  padding: 3px 8px;
  border-radius: $small-border-radius;
}

.titleContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.title {
  align-content: center;
  color: $black;
  font-weight: 700;
  font-size: 18px;
  line-height: 48px;
}

.addButton {
  align-self: flex-end;
  width: 20px;
  margin: 20px 0;
}

.modalRoot {
  width: 465px !important;
}

.fieldsContainer {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.textFormField {
  margin-bottom: 20px;
  :global {
    .MuiInput-root {
      max-height: 150px;
      height: 48px;
    }
    .MuiInputBase-input {
      font-size: 14px;
      color: $black;
      font-weight: 400;
    }
  }
}

.autocompleteField {
  margin-bottom: 20px;
  height: 48px !important;
  :global {
    .MuiAutocomplete-inputRoot {
      height: 48px !important;

      .MuiInputBase-input {
        height: 48px !important;

        font-size: 14px;
        color: $black;
        font-weight: 400;
      }

      .MuiAutocomplete-endAdornment {
        right: 5px;
      }

      &:before {
        display: none;
      }
    }
  }
}

.selectFormField {
  margin-bottom: 20px;
  height: 48px !important;
  background: $white;
  :global {
    .MuiSelect-icon {
      top: 12px;
    }

    .MuiSelect-select {
      font-size: 14px;
      color: $black;
    }
  }
}

.checkBox {
  padding: 0 9px;
}

.amountField {
  height: 24px;
}

.noBottomBorder {
  td,
  th {
    border: none !important;
  }
}

.treasuryNotReady {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60vh;
  text-align: center;
  gap: 20px;
}

.treasuryNotReadyTitle {
  font-size: 18px;
  font-weight: 600;
  color: $tundora;
}
