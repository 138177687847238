@import "../../assets/styles/colors";
@import "../../assets/styles/variables";
@import "../../assets/styles/mixins";

.recordDateTitle {
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
  color: $secondary-color;
}

.selectField {
  width: 105px;
  height: 36px;
}

.selectFieldWide {
  width: 140px;
}

h2 {
  color: $black !important;
}

.table {
  tr {
    td,
    th {
      &:nth-child(1) {
        width: 36px;
      }

      &:nth-child(2) {
        width: 180px;

        .entityLink {
          max-width: calc(100% - 17px);

          &.shortDebtorLabel {
            max-width: calc(100% - 125px);
          }
        }

        .shortDebtorLabel {
          max-width: 70px;
        }
      }

      &:nth-child(3) {
        width: 100px;
      }

      &:nth-child(4) {
        width: 80px;
      }

      &:last-child {
        width: 120px;
      }
    }
  }

  tbody {
    position: relative;

    tr {
      td {
        &:nth-child(5),
        &:nth-child(6),
        &:nth-child(7),
        &:nth-child(8),
        &:nth-child(9),
        &:nth-child(10),
        &:nth-child(11) {
          white-space: pre-wrap;
        }

        &.totals {
          background: $wild-sand;
          font-weight: 700;
        }
      }

      .entityPreviewIcon {
        display: none;
      }

      &:hover {
        .entityPreviewIcon {
          display: inline;
        }
      }
    }
  }

  tfoot {
    tr {
      td {
        &:nth-child(1) {
          width: 396px;
        }

        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4) {
          width: auto;
        }

        &:nth-child(9) {
          width: 120px;
        }
      }
    }
  }
}

.tableClientPage {
  tr {
    td,
    th {
      &:nth-child(1) {
        width: 36px;
      }

      &:nth-child(2) {
        width: 250px;

        .entityLink {
          max-width: calc(100% - 17px);

          &.shortDebtorLabel {
            max-width: calc(100% - 125px);
          }
        }

        .shortDebtorLabel {
          max-width: 140px;
        }
      }

      &:last-child {
        width: 120px;
      }
    }
  }

  tbody {
    position: relative;

    tr {
      td {
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6),
        &:nth-child(7),
        &:nth-child(8),
        &:nth-child(9),
        &:nth-child(10) {
          white-space: pre-wrap;
        }

        &.totals {
          background: $wild-sand;
          font-weight: 700;
        }

        .entityPreviewIcon {
          display: none;
        }

        &:hover {
          .entityPreviewIcon {
            display: inline;
          }
        }
      }
    }
  }

  tfoot {
    tr {
      td {
        &:nth-child(1) {
          width: 286px;
        }

        &:nth-child(2) {
          width: auto;
        }

        &:nth-child(9) {
          width: 120px;
        }
      }
    }
  }
}

.tableClientUserPage {
  tr {
    td,
    th {
      &:nth-child(1) {
        width: 36px;
      }

      &:nth-child(2) {
        width: 250px;
      }

      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6) {
        width: auto;
      }

      &:last-child {
        width: 120px;
      }
    }
  }

  tbody {
    position: relative;

    tr {
      td {
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6),
        &:nth-child(7) {
          white-space: pre-wrap;
        }

        &.totals {
          background: $wild-sand;
          font-weight: 700;
        }

        .entityPreviewIcon {
          display: none;
        }

        &:hover {
          .entityPreviewIcon {
            display: inline;
          }
        }
      }
    }
  }

  tfoot {
    tr {
      td {
        &:nth-child(1) {
          width: 286px;
        }

        &:nth-child(2) {
          width: auto;
        }

        &:nth-child(7) {
          width: 120px;
        }
      }
    }
  }
}

.changedValue {
  white-space: nowrap;
}

.fullScreenModal {
  overflow-y: auto;

  table {
    tbody {
      // Padding + card header + table header + table footer + new filters
      max-height: calc(95vh - 215px) !important;
    }
  }

  .cardContainer {
    height: calc(95vh - 30px);

    :global {
      .MuiCardContent-root {
        height: 100%;
      }

      .MuiTableContainer-root {
        max-height: calc(95vh - 55px);
      }
    }
  }
}

.entityLink, .shortDebtorLabel {
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  text-overflow: ellipsis;
}

.clientUserTitle {
  line-height: normal;
}

.asOfLabel {
  font-size: 14px;
  line-height: 1;
  font-weight: 500;
}

.loadingIndicator {
  margin-left: 10px;
}


.editLink {
  font-size: 12px;
  line-height: 23px;
  margin-left: 10px;
}
