@import "../../assets/styles/colors.scss";
@import "../../assets/styles/variables.scss";
@import "../../assets/styles/mixins.scss";

.dropWrapper {
  position: relative;
}

.dropOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: 1px dashed $input-border-color;
  border-radius: $main-border-radius;
  background-color: $gray-background-color;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.9;
}

.uploadOverlay {
  padding-left: 3%;
}

.manageFilesButtonWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 5px;
}

.manageFilesButton {
  border: none;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;

  &:hover {
    border: none;
  }
}

.uncategorizedFilesUpload {
  max-width: 580px;
}

.uncategorizedFilesList {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 580px;
  padding: 10px 0;
  max-height: calc(70vh - 288px);
  overflow-y: auto;
}

.uncategorizedFileItem {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  height: 38px
}

.uncategorizedFileItemNameContainer {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}

.uncategorizedFileItemName {
  font-size: 16px;
  color: $primary-color !important;
  display: block;
  height: 21px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-decoration: none !important;
  text-align: left;
}

.uncategorizedFileItemTypeContainer {
  width: 220px;

  label {
    display: none;
  }
}

.uncategorizedFileItemTypeSelectField {
  width: 220px;
  height: 38px;

  @include select-field-with-top-label;
}

.uncategorizedFileItemDeleteContainer {
  background: $light-red;
  border-radius: 24px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.uncategorizedFilesButtonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    max-width: 370px;
  }
}
