@import "../../assets/styles/colors";
@import "../../assets/styles/variables";
@import "../../assets/styles/mixins";

.fullScreenModal {
  overflow-y: auto;

  .table {
    padding: 20px;
  }

  table {
    tbody {
      max-height: calc(95vh - 259px) !important;
    }
  }
}

.activeToolbar {
  > div {
      width: 16.6666667%;
  }
}
