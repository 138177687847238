@import '../../assets/styles/colors';
@import '../../assets/styles/variables';

.carouselWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel {
  padding: 10px 30px 20px;
  border-radius: $main-border-radius;
  width: 800px;
  height: 350px;
  overflow: visible;

  > div:nth-child(2) {
    right: -200px;
  }
  > div:nth-child(3) {
    left: -200px;
  }
}

.carouselNavButton {
  &:hover {
    > button {
      opacity: unset !important;
      filter: unset !important;
    }
  }

  > button {
    background-color: transparent !important;

    button {
      width: 32px;
      height: 32px;
      background-color: transparent;
    }
  }
  svg {
    &:hover {
      border-radius: 100px;
      background-color: $thin-blue !important;
      opacity: unset !important;
    }
  }
}

.skeleton {
  -webkit-transform: scale(1);
}

.carouselItem {
  width: 740px;
  height: 320px;

  img {
    max-width: 100%;
  }
}
.hidden {
  display: none;
}
.carouselIndicators {
  margin: 20px 0 0;
}
.checkDebtorsWrapper {
  padding: 10px;
}

.mappingBoxContainer {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  margin-right: 30px !important;
}

.submitButtonContainer {
  margin-right: 30px;
}


.headerContainer {
  width: 100%;
}

.carouselIndicatorsItem {
  svg {
    font-size: 12px;
    fill: $slate-gray;
    opacity: 0.2;
    circle {
      r: 12 !important;
    }
  }
  margin: 0 3px;

  &.carouselIndicatorsItemActive {
    svg {
      fill: $primary-color;
      width: 12px;
      opacity: 1 !important;
      circle {
        r: 12 !important;
      }
    }
  }
}

.section {
  flex-direction: column;
}

.sectionChecks {
  flex-direction: column;
  margin-bottom: 120px;
}

.checkDebtorsTitle {
  color: $primary-color;
  font-size: 18px;
  line-height: 20px;
  font-weight: bold;
}

.checkDebtorsTable {
  tr {
    td,
    th {
      &:nth-child(1) {
        width: 120px;
      }

      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4)
      {
        width: 30%;
      }

      &:nth-child(5) {
        width: 200px;
      }

      &:nth-child(6) {
        width: 50px;
      }
    }
  }

  tbody {
    max-height: 300px !important;

    td {
      :global {
        .MuiFormControl-root {
          > div {
            width: 100% !important;
            max-width: none !important;
          }
        }
        .MuiAutocomplete-root {
          border: none !important;
        }
      }
    }
  }
}

.checkReconciliationTable {
  tr {
    td,
    th {
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        width: 27%;
      }

      &:nth-child(1) {
        width: 19%;
      }
    }
  }
  tbody {
    max-height: 300px !important;
  }
}
.nonClientRelatedCash {
  tr {
    td,
    th {
      &:nth-child(1),
      &:nth-child(2) {
        width: 10%;
      }
      &:nth-child(3),
      &:nth-child(4) {
        width: 24%;
      }
      &:nth-child(5),
      &:nth-child(6) {
        width: 13.5%;
      }
      &:nth-child(7) {
        width: 5%;
      }
    }
  }
  tbody {
    max-height: 300px !important;

    td {
      :global {
        .MuiFormControl-root {
          > div {
            width: 100% !important;
            max-width: none !important;
          }
        }
        .MuiAutocomplete-root {
          border: none !important;
        }
      }
    }
  }
}

.nonClientRelatedCash,
.checkDebtorsTable,
.checkReconciliationTable {
  tbody {
    tr {
      cursor: pointer;
      margin-top: 4px;
      margin-bottom: 4px;
      background: $white;

      &.validRow {
        background: $valid-row-green;
      }

      &.inValidRow {
        background: $red-background;
      }
      &.deletedRow {
        cursor: auto;
        opacity: 0.5;
      }

      &.activeRow {
        background: $white;
      }

      .actionBtn {
        display: none;
      }

      &:hover {
        .actionBtn {
          display: inline-flex;
        }
      }
    }
  }
}

.filterHeaders {
  font-size: 12px !important;
}

.actionBtn {
  cursor: pointer;
}

.checksReconciliationTitle {
  position: relative;
  padding: 0;
  margin: 40px 0 24px;

  span {
    font-size: 32px;
    line-height: 36px;
    color: $primary-color;
  }

  &:before {
    position: absolute;
    top: 0;
    left: -40px;
    width: 3px;
    height: 100%;
    content: ' ';
    background: $primary-color;
  }
}

.activeToolbar {
  > div {
    &:nth-child(1) {
      width: 73%;
    }

    &:nth-child(2) {
      width: 22%;
    }
  }
}

.actionsRow {
  min-height: 48px;
  box-sizing: content-box;
}


.selectCell {
  color: $transparent-gray;
 
}

.checkImageCarouselRow{
  td {
    > div {
      width: 96% !important;
      margin-left: 5px;
    }
  }
}

.hidden {
  visibility: hidden;
}