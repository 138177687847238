@import "../../assets/styles/variables";
@import "../../assets/styles/colors";

.clientBannersContainer {
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  gap: 10px
}

.clientBanner {
  display: flex;
  height: 60px;
  padding: 10px 16px;
  justify-content: space-between;
  align-items: center;
  gap: 6px;
  flex-shrink: 0;
  width: 100%;
  background: rgba($french-pass, 0.75);
  border-radius: $main-border-radius;
  color: $secondary-color;
}

.clientBannerContent {
  flex: 1;
  font-size: 14px;
  line-height: 21px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.title {
  font-weight: 600;
}

.link {
  color: $secondary-color !important;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  text-align: right !important;
  text-decoration-line: underline !important;
  cursor: pointer;

  &:hover {
    color: $black !important;
  }
}

.modalTitle {
  font-size: 18px;
}

.cashDominionCustomerContainer {
  margin: 0;
  padding: 0 0 0 17px;
  max-height: 40vh;
  overflow-y: auto;
}

.cashDominionCustomer {
  color: $secondary-color !important;
  font-size: 14px;
  padding: 7px 0;
  text-align: left;

  +.cashDominionCustomer {
    margin-top: 10px;
  }
}
