@import "../../assets/styles/colors.scss";

.card {
  width: 100%;
}

.editButtonIcon {
  margin-left: 15px;
  min-width: 55px;
  height: 40px;
  line-height: 24px;
}

.longTextRow {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 7px;
}

.label {
  flex: 1;
}

.value {
  flex: 1;

  :global {
    .MuiInput-root {
      width: 100% !important;
    }

    [class*="KeyboardDatePicker_root"] {
      width: 100% !important;
    }

    [class*="SelectField_root"] {
      background: $white;
    }
  }
}
