@import '../../assets/styles/colors';
@import '../../assets/styles/variables';

.scrollableDiv {
  overflow-y: scroll;
  max-height: 240px !important;
}

.activeToolbar {
  padding: 0 32px 0 24px;
  order: 3;

  > div {
    width: 15%;

    &:nth-child(1) {
      width: 25%;
    }
  }
}

.table {
  thead {
    tr {
      margin: -8px 0;
    }
  }
}
