@import '../../assets/styles/colors';
@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.table {
  overflow: auto;
  margin-left: -10px;
  width: calc(100% + 10px);

  thead {
    th {
      border-bottom: none;
      &:first-child {
        background-color: #a0b4c0;
      }

    }
  }

  .tableHeadResizing {
    th {
      pointer-events: none;
    }
  }

  tbody {
    display: table !important;
    max-height: initial !important;
    border-collapse: separate !important;
    position: relative;

    tr {
      td {
        &:nth-child(1) {
          position: relative;
          overflow: visible;
        }
      }
    }
  }

  tr {
    td,
    th {
      &:nth-child(1) {
        padding: 0;
        position: sticky !important;
        left: 0;
        width: 10px;
        background: $white;
        border: none !important;
        z-index: 2;
        overflow: visible;

        &.withExpandIcon {
          z-index: 3;
        }
      }
      &:nth-child(2) {
        position: sticky !important;
        background-color: inherit !important;
        left: 10px;
        z-index: 1;
        min-width: 225px;
        width: 225px;
        text-align: left;
        //box shadow on the right
        box-shadow: 7px 20px 20px 0 rgba(0, 0, 0, 0.2);
      }

      &:nth-child(n + 3) {
        min-width: 175px;
        width: 175px;
        padding-top: 0;
        padding-bottom: 0;

        > div {
          font-weight: 700;
        }

        &.blueColumn {
          background: $slate-gray !important;
          color: $white;
        }
      }
    }

    &.headerRow {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      background: $catskill-white;

      td {
        border-top: 1px solid $cadet-blue;
        border-bottom: 1px solid $cadet-blue;
      }

      &.headerRowThinBorder {
        td {
          border-bottom: none;
        }
      }
    }

    &.bottomRow {
      font-weight: 600 !important;
      font-size: 12px;
      line-height: 16px;
      background: $slate-gray !important;
      color: $white;

      &:hover {
        color: $slate-gray !important;
      }
    }

    &:not(.keyRow) {
      td {
        &:nth-child(2) {
          padding-left: 30px;
        }
      }
    }
  }

  :global {
    tr {
      &.activeRow {
        td {
          &:nth-child(1) {
            background: $white !important;
          }

          > div {
            color: $tundora !important;
          }
        }
      }
    }
  }
}

.activeToolbar {
  overflow-x: auto !important;
  display: inline-flex !important;
  width: 100% !important;
  flex-shrink: 0;

  &::-webkit-scrollbar {
    display: none;
  }

  > div {
    flex-shrink: 0;

    &:nth-child(1) {
      position: sticky !important;
      background-color: white !important;
      left: 0;
      width: 225px;
      text-align: left;
      //box shadow on the right
      box-shadow: 7px 20px 20px 0 rgba(0, 0, 0, 0.2);
    }

    &:not(:first-child) {
      width: 175px !important;
    }
  }
}

.tableHead {
  background-color: $row-background-color;
}

.accountHeader {
  font-weight: 700 !important;
  font-size: 12px !important;
  line-height: 16px;
  color: $secondary-color;
  margin-left: 20%;
}

.financialHeaders {
  margin-left: 5%;
  font-weight: 400;
  color: $black;
}

.financialHeadersActive {
  margin-left: 5%;
  font-weight: 400;
}

.financialBottomLine {
  color: $white;
  font-weight: 600;
  font-size: 12px;
  margin-left: 5%;
}

.bottomLineAmount {
  color: $white;
  font-weight: 600;
  font-size: 12px;
  position: relative;

  .dataGrowth {
    top: 0;
  }
}

.amountHolder {
  font-weight: 400;
  font-size: 12px;
  line-height: 26px;
  position: relative;
}

.fullScreenModal {
  overflow-y: auto;

  .table {
    max-height: calc(95vh - 155px) !important;
  }
  table {
    tbody {
      max-height: initial !important;
    }
  }
}

.expandIcon {
  width: 15px;
  height: 15px;
  position: absolute;
  bottom: -7px;
  left: 3px;
  cursor: pointer;

  svg {
    width: 15px;
    height: 15px;
  }
}

.table {
  thead {
    width: 100% !important;
    th {
      &:nth-child(1) {
        z-index: 51;

        .expandIcon {
          top: 14px;

          &.expandIconCustom {
            top: auto;
            bottom: -7px;
          }
        }
      }
    }
  }
}

.editLink {
  font-size: 12px;
  line-height: 23px;
  cursor: pointer;
  color: $primary-color;
}

.selectField {
  width: 104px;
  height: 36px;

  @include select-field-with-top-label;

}

.lineItemsField {
  width: 200px;
  height: 36px;

  @include select-field-with-top-label;

}


.dataGrowth {
  padding: 2px 4px;
  border-radius: $small-border-radius;
  display: inline-block;
  position: absolute;
  right: 115px;
  top: 3px;
  line-height: 16px;
  max-width: 60px;
  overflow: hidden;
  text-align: right;

  color: $red-text;
  background: $red-background;

  &.dataGrowthPositive {
    color: $green-text;
    background: $green-background;
  }
}


.quickFilters{
  font-size: 14px;
  font-weight: 400;
  width: 310px;
}
.quickFilterButton{
  cursor: pointer;
  margin-left: 10px;
}

.quickFilterButtonActive{
  cursor: pointer;
  color: $white;
  background: $primary-color;
}

.contentWithBorder {
  border: 1px solid $border-color;
  border-top:none;
}

.headerTooltip {
  padding: 8px 12px;
  background: $white;
  color: $tundora;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.35);
  font-size: 12px;
  line-height: 1.25;
}

.headerTooltipArrow {
  color: $white;
}

.headerTooltipContent {
  min-width: 80px;
  display: flex;
  flex-direction: column;
}

.headerTooltipContentHeader {
  font-weight: 700;
  margin-top: 16px;

  &:first-of-type {
    margin-top: 0;
  }
}
