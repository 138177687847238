@import '../../../assets/styles/colors';
@import '../../../assets/styles/mixins';

.table {
  tr {
    td,
    th {
      &:nth-child(1) {
        width: 50px;
      }

      &:nth-child(2) {
        width: 100px;
      }
    }
  }
}

.loanLedgerWiresTable {
  tr {
    td,
    th {
      width: auto !important;

      &:nth-child(4) {
        width: 60px !important;
      }
    }

    &.deletedRow {
      opacity: 0.5;
    }
  }
}

.value {
  text-align: left;
  width: 100%;
  display: block;
  margin-top: 10px;
}

.button {
  align-items: center;
}

.plusIcon {
  path {
    stroke: $primary-color;
  }
}

.highlightedCurrent {
  background: $nepal;
  color: $white !important;
}

.activeToolbar {
  order: 1;

  > div {
    width: 13%;

    &:nth-child(1) {
      width: 12vw;
    }

    &:nth-child(2) {
      width: 6vw;
    }

    &:nth-child(3) {
      width: 11vw;
    }
    &:nth-child(4) {
      width: 12vw;
    }
    &:nth-child(5) {
      width: 10vw;
    }
    &:nth-child(6) {
      width: 10.5vw;
    }
    &:nth-child(7) {
      width: 10vw;
    }
  }
}

.activeToolbarParticipants {
  order: 1;

  > div {
    width: 13%;

    &:nth-child(1) {
      width: 8vw;
    }

    &:nth-child(2) {
      width: 8vw;
    }

    &:nth-child(3) {
      width: 4vw;
    }

    &:nth-child(4) {
      width: 6vw;
    }

    &:nth-child(5) {
      width: 7vw;
    }

    &:nth-child(6) {
      width: 7vw;
    }

    &:nth-child(7) {
      width: 7vw;
    }
  }
}

.selectField {
  width: 120px;
  height: 36px;

  @include select-field-with-top-label;
}
