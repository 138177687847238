@import "../../assets/styles/colors.scss";
@import "../../assets/styles/variables.scss";

.title {
  position: sticky;
  left: 0;
  display: table;
}
.table {
  display: block;
  width: 100%;

  &.tableThin {
    width: 100%;

    table {
      thead, tbody {
        max-width: calc(100vw - 551px);

        @media only screen and (min-width: 1800px) {
          max-width: calc(100vw - 651px);
        }
      }
    }
  }

  table thead {
    max-width: calc(100vw - 205px);
    display: block;
    overflow-y: auto;
    table-layout: unset;
    margin: 0;

    // Hide scrollbar
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }

    tr {
      display: table;
      width: 100%;
      table-layout: fixed;
      border-spacing: 0;
    }
  }

  tbody {
    max-width: calc(100vw - 205px);
    border-collapse: separate !important;
    > div > div {
      overflow: inherit !important; //allows for sticky left columns
    }
    tr {
      &.activeRow {
        background: $dropdown-selected-color;
      }

      td {
        :global {
          .MuiFormControl-root {
            height: auto !important;

            > div {
              height: auto !important;

              &:focus {
                height: auto !important;
              }
            }
          }
        }
      }
    }
  }

  tr {
    td,
    th {
      &:nth-child(1) {
        width: 225px;
        left: 0;
      }

      &:nth-child(2) {
        width: 200px;
        left: 225px;
      }

      &:nth-child(3) {
        width: 200px;
        left: calc(225px + 200px);
      }

      &:nth-child(4) {
        width: 225px;
        left: calc(200px * 3 + 25px);
        box-shadow: 13px 20px 20px 0px rgba(0, 0, 0, 0.2);
      }

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        position: sticky !important;
        background-color: inherit !important;
        z-index: 1;
        text-align: left;
      }

      &:not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(3)):not(:nth-child(4)) {
        width: 150px;
      }
    }

    td {
      &:nth-child(1) {
        text-align: left;
      }
    }
  }
}

.firstLevelRow {
  font-weight: 800;
  border-top: 2px solid $black;
  height: 32px !important;
}
.secondLevelRow {
  font-weight: 800;
  border-top: 1px solid $black;
  height: 31px !important;
}
.headerRow{
  font-weight: 700;
}
.secondLevel {
  padding-left: 20px;
}

.thirdLevel {
  padding-left: 40px;
}

.fourthLevel {
  padding-left: 60px;
}

.fifthLevel {
  padding-left: 80px;
}

.sixthLevel {
  padding-left: 100px;
}

.predictionCard {
  display: inline-block;
  font-size: $main-font-size;
  line-height: 14px;
  padding: 3px 7px;
  border-radius: $main-border-radius;
  background: $green-background;
  color: $green-text;
  cursor: pointer;
}

.predictionCardActive {
  display: inline-block;
  font-size: $main-font-size;
  line-height: 14px;
  padding: 3px 7px;
  border-radius: $main-border-radius;
  background: $green-text;
  color: $white;
  cursor: pointer;
}

.chip {
  max-height: 40%;
  max-width: 70%;
  color: $white;
  cursor: pointer;
  margin-right: 2px;
  margin-top: 2px;
  margin-bottom: 1px;
  font-size: 10px !important;
  border-radius: 4px;
  background-color: $primary-color;
  path {
    fill: white;
  }
}

.colorPrimary {
  color: $primary-color;
}

.itemsCount {
  flex: 1;
  font-size: $main-font-size;
  padding-top:6px;
  line-height: 20px;
  text-align: right;
  color: $dark-blue;
}

.activeToolbar {
  overflow-x: auto !important;
  display: inline-flex !important;
  width: 100% !important;
  flex-shrink: 0;
  &::-webkit-scrollbar {
    display: none;
  }

  > div {
    flex-shrink: 0;

    &:nth-child(1) {
      position: sticky !important;
      background-color: white !important;
      left: 0;
      z-index: 1;
      width: 852px;
      text-align: left;
      //box shadow on the right
      box-shadow: 7px 20px 20px 0px rgba(0, 0, 0, 0.2);
    }

    &:not(:first-child) {
      width: 150px !important;
    }
  }
}

.textRight {
  text-align: right !important;
  padding-right: 5px !important;
}

.link {
  display: inline-block;
  font-size: $main-font-size;
  line-height: 20px;
  font-weight: 600;
  color: $primary-color;
  margin-right: 20px;
}

.linkIcon {
  margin-left: 7px;
  vertical-align: middle;

  path {
    stroke: $primary-color;
  }
}

.fullScreenModal {
  height: calc(95vh);
  overflow-y: auto;

  :global {
    .MuiTableContainer-root {
      // Padding + card header
      max-height: calc(95vh - 22px) !important;
      max-width: 100% !important;
    }
  }

  table {
    tbody {
      // Padding + card header + table header + new filters
      max-height: calc(95vh - 185px) !important;
    }
  }

  .table {
    width: 110%;

    table {
      thead, tbody {
        max-width: 90vw;
      }
    }
  }
}

.fullScreenModalRoot {
  height: calc(95vh);
}

.focused {
  background-color: $input-hovered-background-color;
  height: auto !important;
}

.focusedInput {
  outline: 1.5px solid $input-label-color !important;
}

.popper {
  display: block;
  box-shadow: 0 4px 25px rgba(0, 26, 69, 0.2);
  border-radius: $main-border-radius;

  > div {
    margin: 0;
  }

  ul {
    padding: 0;
    color: $dark-blue;

    > li {
      &:not(:first-of-type) {
        :global {
          .MuiAutocomplete-groupLabel {
            border-top: 2px dashed $gothic;
          }
        }
      }
    }

    :global {
      .MuiAutocomplete-option {
        box-sizing: content-box;
        font-size: $main-font-size;
        height: 20px;
        padding: 10px;

        &:hover,
        &[data-focus="true"] {
          background: $dropdown-hovered-color;
        }

        &[aria-selected="true"] {
          background: $dropdown-selected-color;

          &:hover {
            background: $dropdown-selected-color;
          }
        }
      }
    }
  }
}

.totalRowPrefix{
  color: $black;
}

.selectField {
  width: 105px;
  height: 36px;
}
