@import '../../assets/styles/colors';
@import '../../assets/styles/variables';

.scrollableDiv {
  overflow-y: scroll;
  max-height: 240px !important;
}

.activeToolbar {
  padding: 0 64px 0 48px;
  order: 4;

  > div {
    width: 15%;

    &:nth-child(1) {
      width: 25%;
    }
  }
}

.table {
  thead {
    tr {
      margin: -8px 0;
    }
  }
  tr {
    td, th {
      &:nth-child(1) {
        width: 25% !important;
      }
      &:nth-child(2) {
        width: auto !important;
      }
    }
  }
}
