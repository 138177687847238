@import '../../assets/styles/colors';
@import '../../assets/styles/variables';

.mappingBoxContainer {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  margin-right: 30px !important;
}

.submitButtonContainer {
  margin-right: 30px;
}



.headerContainer {
  width: 100%;
}

.checkDebtorsTitle {
  color: $primary-color;
  font-size: 18px;
  line-height: 20px;
  font-weight: bold;
}

.checkDebtorsTable {
  tr {
    td,
    th {
      &:nth-child(1) {
        width: 15%;
      }

      &:nth-child(2),
      &:nth-child(3) {
        width: 29%;
      }

      &:nth-child(4) {
        width: 22%;
      }

      &:nth-child(5) {
        width: 5%;
      }
    }
  }

  tbody {
    max-height: 300px !important;

    td {
      :global {
        .MuiFormControl-root {
          > div {
            width: 100% !important;
            max-width: none !important;
          }
        }
        .MuiAutocomplete-root {
          border: none !important;
        }
      }
    }
  }
}

.checkReconciliationTable {
  tr {
    td,
    th {
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        width: 27%;
      }

      &:nth-child(1) {
        width: 19%;
      }
    }
  }
  tbody {
    max-height: 300px !important;
  }
}
.nonClientRelatedCash {
  tr {
    td,
    th {
      &:nth-child(1),
      &:nth-child(2) {
        width: 10%;
      }
      &:nth-child(3),
      &:nth-child(4) {
        width: 24%;
      }
      &:nth-child(5),
      &:nth-child(6) {
        width: 13.5%;
      }
      &:nth-child(7) {
        width: 5%;
      }
    }
  }
  tbody {
    max-height: 300px !important;

    td {
      :global {
        .MuiFormControl-root {
          > div {
            width: 100% !important;
            max-width: none !important;
          }
        }
        .MuiAutocomplete-root {
          border: none !important;
        }
      }
    }
  }
}

.nonClientRelatedCash,
.checkDebtorsTable,
.checkReconciliationTable {
  tbody {
    tr {
      cursor: pointer;
      margin-top: 4px;
      margin-bottom: 4px;
      background: $white;

      &.validRow {
        background: $valid-row-green;
      }

      &.inValidRow {
        background: $red-background;
      }
      &.deletedRow {
        cursor: auto;
        opacity: 0.5;
      }

      &.activeRow {
        background: $white;
      }

      .actionBtn {
        display: none;
      }

      &:hover {
        .actionBtn {
          display: inline-flex;
        }
      }
    }
  }
}

.filterHeaders {
  font-size: 12px !important;
}

.actionBtn {
  cursor: pointer;
}

.checksReconciliationTitle {
  position: relative;
  padding: 0;
  margin: 40px 0 24px;

  span {
    font-size: 32px;
    line-height: 36px;
    color: $primary-color;
  }

  &:before {
    position: absolute;
    top: 0;
    left: -40px;
    width: 3px;
    height: 100%;
    content: ' ';
    background: $primary-color;
  }
}

.activeToolbar {
  > div {
    &:nth-child(1) {
      width: 73%;
    }

    &:nth-child(2) {
      width: 22%;
    }
  }
}

.actionsRow {
  min-height: 48px;
  box-sizing: content-box;
}
