@import '../../../assets/styles/colors';
@import '../../../assets/styles/variables';


.input {
    font-size: 12px;
    background-color: #ffffff;
    border-radius: 5px;
    padding-left: 5px;
    border: 1px solid #dee1e5;
}




.tagsInput {
    font-size: $main-font-size;
    line-height: 20px;

    color: $input-color;
    border-radius: $main-border-radius;
    border: none;
    width: 100%;
    background: transparent;
    border-color: transparent;

}


.popper {
    display: block;
    box-shadow: 0 4px 25px rgba(0, 26, 69, 0.2);
    border-radius: $main-border-radius;

    >div {
        margin: 0;
    }

    ul {
        padding: 0;
        color: $dark-blue;

        >li {
            &:not(:first-of-type) {
                :global {
                    .MuiAutocomplete-groupLabel {
                        border-top: 2px dashed $gothic;
                    }
                }
            }
        }

        :global {
            .MuiAutocomplete-option {
                box-sizing: content-box;
                font-size: $main-font-size;
                height: 20px;
                padding: 10px;

                &:hover,
                &[data-focus="true"] {
                    background: $dropdown-hovered-color;
                }

                &[aria-selected="true"] {
                    background: $dropdown-selected-color;

                    &:hover {
                        background: $dropdown-selected-color;
                    }
                }
            }
        }
    }
}

.popperSmall{
    ul{
        max-height: 200px !important;
    }

}