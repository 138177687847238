@import '../../../assets/styles/colors';
@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.fullScreenModal {
  height: calc(95vh);
  overflow-y: auto;
  overflow-x: visible;
  :global {
    .MuiTableContainer-root {
      // Padding + card header
      max-height: calc(95vh - 22px) !important;
      max-width: 100% !important;
    }
  }
}

.modal {
  width: calc(100% - 191px);
  height: calc(100vh - $header-height - 100px);
  display: flex;
  flex-direction: column;
  margin-left: 4px;
}

.modalTitle {
  display: flex;
  align-items: center;
  gap: 527px;
  font-size: 18px;
  font-weight: 800;
  letter-spacing: 0.2px;
  padding-bottom: 22px;
}

.modalBody {
  display: flex;
  flex-direction: column;
  flex: 1;
  max-height: calc(95vh - 30px) !important;
  padding: 20px 28px;
}

.scrollContainer {
  .noteEditorEditor {
    :global {
      .DraftEditor-root {
        max-height: 70px !important;
        overflow: auto;
      }
    }
  }
}

.notesContainer {
  overflow: visible !important;
  padding: 0;
  height: 100%;
}

.notesCardContainer {
  display: flex;
  flex-direction: row;
  max-height: calc(100vh - 100px);
  height: 100%;
}

.quickFiltersContainer {
  display: flex;
  width: 191px;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  padding: 28px;
  border-right: 1px solid $border-color;
  cursor: pointer;
}

.quickFiltersContainerDiligence {
  width: 316px !important;
  overflow-y: scroll;
  overflow-x: hidden;
}

.quickFilter {
  display: flex;
  width: 191px;
  padding: 8px 24px 8px 2px;
  align-items: center;
  gap: 10px;
}

.quickFilterDiligence {
  width: 259px !important;
  cursor: pointer;
}

.quickFilterIcon {
  display: flex;
  width: 24px;
  height: 18px;
  padding: 2.25px 1.5px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  > svg {
    width: 24px !important;
  }
}

.quickFilterText {
  font-size: 14px;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: 0.2px;
  color: $slate-gray;
}

.clearAllButton {
  background-color: transparent;
  text-decoration: underline;
  color: $primary-color;
  height: unset;
  padding: 0;

  &:hover {
    background-color: transparent;
  }
}

.activeFilter {
  > div {
    color: $primary-color;
    > svg {
      > path {
        stroke: $primary-color;
      }
    }
  }
}

.activeFilterFill > div > svg {
  fill: $primary-color;
}

.documentIcon {
  > path {
    stroke: var(--stroke-color, $slate-gray) !important;
  }
}

.notesList {
  margin-top: 5px;
  flex-grow: 1;
  padding-bottom: 5px;
  overflow-x: visible !important;
  overflow-y: auto !important;

  @include thin-scrollbar;
}

.infiniteScroll {
  overflow: visible !important;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.startAdornment {
  margin-right: 3px;
  path {
    fill: $black;
  }
}

.actionButtonContainer {
  display: flex;
  cursor: pointer;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.actionButtonContainerFilterContainer {
  flex: 1;

  > div {
    padding: 0;

    > div {
      padding: 0;
    }
  }
}

.actionsContainer {
  padding: 4px 75px 16px 0px;
  margin-bottom: 12px;
  border-bottom: 1px solid $border-color;
}

.searchField {
  height: 46px;
  width: 504px;

  :global {
    .MuiInputBase-root {
      padding-left: 15px;
      height: 46px;
      width: 504px;

      input {
        font-size: medium;
      }
    }
  }
}

.exportButton {
  height: 48px;
  width: 90px;
  display: flex;
  float: right;
}

.exportAddContainer {
  display: flex;
  gap: 16px;
}

.hiddenButton {
  display: none;
}

.button {
  cursor: pointer;
  border-radius: $small-border-radius;
  width: 46px;
  height: 46px;
  box-sizing: border-box;
  padding: 13px;
  margin-left: 15px;

  &:hover {
    background-color: $button-border-color;
  }
}

.buttonActive {
  background-color: $debtor-gray;
}

.circularLoader {
  display: flex;
  justify-content: center;
}

.select {
  width: 168px;
  height: 48px;
  :global {
    .MuiSelect-select {
      text-align: left;
    }
  }
}

.filterButtonTitle {
  font-size: 14px;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: 0.2px;
  text-align: left;
}

.sortMenuPopoverPaper {
  width: 175px;
  padding: 5px;
}

.sortMenuItem {
  padding: 10px 16px;
  font-size: 12px;
  line-height: 15px;
  color: $secondary-color;

  &:hover {
    background-color: $gallery;
  }

  &.sortMenuItemSelected {
    background-color: $button-border-color;

    &:hover {
      background-color: $gallery;
    }
  }
}

.tagSectionHeader {
  border-bottom: 1px solid $button-border-color;
  margin-bottom: 1.5vh;
  display: flex;
  cursor: pointer;
  gap: 18px;
  padding: 0 0 10px 16px;
  align-items: center;
  > svg {
    width: 14px;
    height: 14px;
    > path {
      fill: $primary-color;
    }
  }
}

.tagSectionTitle {
  color: $black;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.2px;
}

.dueDiligenceNotesList {
  overflow-y: scroll;
  padding-top: 1.5vh;
}

.tagSection {
  margin-bottom: 1.5vh;
  min-height: 3.5vh;
}

.tagSectionNotes {
  gap: 12px;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  &:last-child {
    padding-bottom: 10px;
  }
}
