@import '../../../assets/styles/colors';
@import '../../../assets/styles/variables';

:global {
  .MuiInput-underline.Mui-error:after {
    border: none;
  }

  .MuiInput-underline::after {
    border: none;
  }
}

.root {
  font-size: $main-font-size;
  line-height: 20px;
  background-color: $input-background-color;
  color: $black;
  border-radius: $small-border-radius;
  border: none;

  &:focus {
    background-color: $input-hovered-background-color;
  }
}

.input {
  font-size: $main-font-size;
  &::before {
    display: none;
  }

  input {
    padding: 8px 14px !important;
    line-height: 20px;
    height: 20px;
    cursor: text;
  }
}

.error {
  &::after {
    border-bottom: none;
  }
}

.popper {
  display: block;
  box-shadow: 0 4px 25px rgba(0, 26, 69, 0.2);
  border-radius: $main-border-radius;
  overflow: hidden;

  > div {
    margin: 0;
  }

  ul {
    padding: 0;
    color: $dark-blue;

    > li {
      &:not(:first-of-type) {
        :global {
          .MuiAutocomplete-groupLabel {
            border-top: 2px dashed $gothic;
          }
        }
      }
    }

    :global {
      .MuiAutocomplete-option {
        box-sizing: content-box;
        font-size: $main-font-size;
        height: 20px;
        padding: 10px;

        &:hover, &[data-focus="true"] {
          background: $dropdown-hovered-color;
        }

        &[aria-selected="true"] {
          background: $dropdown-selected-color;

          &:hover {
            background: $dropdown-selected-color;
          }
        }
      }
    }
  }
}

.focused {
  background-color: $dropdown-selected-color;
}
