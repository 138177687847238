@import '../../../assets/styles/colors';

.table {
  tr {
    td,
    th {
      &:nth-child(1) {
        width: 50px;
      }
      &:nth-child(6) {
        width: 60px;
      }
    }
  }
}

.amendmentDetailsRow {
  text-align: left;
  line-height: 20px;
  > span {
    border-top: 1px;
  }
}

.divider {
  border-top: 1px solid $catskill-white;
  margin-bottom: 5px;
}

.title {
  display: block;
}

.icon {
  margin-right: 10px;
}

.value {
  text-align: left;
  width: 100%;
  display: block;
  margin-top: 10px;
}

.infiniteScroll {
  overflow: visible !important;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.textFormField {
  width: 100%;

  :global {
    .MuiInput-root {
      width: 100% !important;
      height: 48px;
    }

    .MuiInput-input {
      font-size: 14px;
    }
  }
}

.isPending {
  opacity: 0.5;
}

.dateFormField {
  width: 100%;
  height: 48px !important;

  :global {
    .MuiInput-root {
      padding: 8px 14px;
      height: 48px !important;
      width: 100% !important;
    }

    .MuiInput-input {
      font-size: 14px;
    }
  }
}

.restartTerm {
  padding: 0;
}
