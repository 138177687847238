@import "../../assets/styles/colors";
@import "../../assets/styles/variables";

.table {

  tr {
    td {
      :global {
        .MuiFormControl-root {


          > div {

            &:focus {
            }
          }
        }
      }
    }

    td, th {
      &:nth-child(1) {
        width: 3%;
      }

      &:nth-child(2) {
        width: 27%;
      }

      &:nth-child(3) {
        width: 10%;
      }

      &:nth-child(4) {
        width: 15%;
      }

      &:nth-child(5) {
        width: 15%;
      }

      &:nth-child(6) {
        width: 15%;
      }

      &:nth-child(7) {
        width: 15%;
      }
    }
  }

  tbody {
    &.inModalTableBody {
      max-height: calc(95vh - 320px) !important;
    }

    &.fullWidthTableBody {
      max-height: calc(100vh - 400px) !important;
    }

    tr {
      background: $white;

      &.activeRow {
        background: $dropdown-selected-color;
      }

      td {
        :global {
          .MuiFormControl-root {
            > div {
              width: 100% !important;
              max-width: none !important;;

            }
          }
        }
      }
    }
  }
}

.nestedTable {
  tr {
    td, th {
      &:nth-child(1) {
        width: 60%;
      }

      &:nth-child(2) {
        width: 20%;
      }

      &:nth-child(3) {
        width: 20%;
      }
    }
  }

  tbody {
    &.scrollableDiv {
      overflow-y: scroll;
      max-height: 260px !important;
    }
  }
}

.chip {
  color: $white;
  cursor: pointer;
  max-width: 39%;
  max-height: 90%;
  margin-right: 2px;
  margin-top: 2px;
  margin-bottom: 1px;
  font-size: 11px !important;
}

.activeToolbar {
  > div {
    &:nth-child(1) {
      width: 30%;
    }

    &:nth-child(2) {
      width: 10%;
    }
  }
}

.nestedActiveToolbar {
  padding: 0 32px 0 24px;

  > div {
    &:nth-child(1) {
      width: 80%;
    }

    &:nth-child(2) {
      width: 20%;
    }
  }
}

.tooltipTitles {
  font-size: $main-font-size;
  margin: 0 10px 10px;
  width: 50%;
}

.taggingField {
  border: 1px solid $input-border-color;
  border-radius: $small-border-radius;
  height: 26px;
}
