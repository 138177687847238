@import '../../assets/styles/colors';

tr {
  &.collectionAggregationRow {
    height: 33px;
  }
}

.collectionAggregationRow {
  background: $dropdown-hovered-color !important;

  td {
    &:first-of-type {
      padding-left: 0;
    }
  }
}

.iconButton {
  cursor: pointer;

  + .iconButton {
    margin-left: 3px;
  }
}
