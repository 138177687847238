@import '../../assets/styles/colors';
@import '../../assets/styles/variables';

.table {
  tr {
    height: 30px;

    td, th {
      &:nth-child(1) {
        width: 3%;
      }

      &:nth-child(2) {
        width: 32%;
      }
    }
  }

  tfoot {
    tr {
      td {
        &:nth-child(1) {
          width: 35%;
        }

        &:nth-child(2) {
          width: auto;
        }
      }
    }
  }

  tbody {
    position: relative;

    .parentRow {
      position: sticky;
      top: 0;
      z-index: 1;
    }
  }
}

.activeToolbar {
  order: 2;

  > div {
    width: 13%;

    &:nth-child(1) {
      width: 35%;
    }
  }
}

.fullScreenModal {
  overflow-y: auto;

  table {
    tbody {
      // Padding + card header + table header + table footer + new filters
      max-height: calc(95vh - 306px) !important;
    }
  }
}

