@import '../../assets/styles/colors';
@import '../../assets/styles/variables';

.table {
  tr {
    height: 30px;
  }

  tbody {
    position: relative;

    tr {
      td {
        &.totals {
          background: $wild-sand;
          font-weight: 700;
        }
      }
    }
  }
}

.arTable {
  tr {
    td,
    th {
      &:nth-child(1) {
        width: 36px;
      }

      &:nth-child(2) {
        width: 8vw;
      }

      &:nth-child(7) {
        width: 5vw;
      }

      &:nth-child(8),
      &:nth-child(9),
      &:nth-child(10) {
        width: 5vw;
      }

      &:nth-child(11) {
        width: 5vw;
      }

      &:nth-child(12) {
        width: 5vw;
      }

      &:nth-child(13) {
        width: 5vw;
      }
    }
  }

  tfoot {
    tr {
      td {
        &:nth-child(1) {
          width: 9vw;
        }

        &:nth-child(2) {
          width: auto;
        }

        &:nth-child(6) {
          width: 10vw;
        }

        &:nth-child(7),
        &:nth-child(8),
        &:nth-child(10) {
          width: 5vw;
        }

        &:nth-child(9) {
          width: 5vw;
        }
      }
    }
  }
}

.apTable {
  tr {
    td,
    th {
      &:nth-child(1) {
        width: 36px;
      }

      &:nth-child(2) {
        width: 250px;
      }

      &:nth-child(7),
      &:nth-child(8) {
        width: 100px;
      }

      &:nth-child(9),
      &:nth-child(10) {
        width: 120px;
      }
    }
  }

  tfoot {
    tr {
      td {
        &:nth-child(1) {
          width: 286px;
        }

        &:nth-child(2) {
          width: auto;
        }

        &:nth-child(6) {
          width: 200px;
        }

        &:nth-child(7),
        &:nth-child(8) {
          width: 120px;
        }
      }
    }
  }
}

.activeToolbar {
  order: 1;
}

.arActiveToolbar {
  display: flex;

  > div {
    &:nth-child(1) {
      padding-left: 26px;
      width: 272px;
    }

    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5) {
      flex: 1;
    }

    &:nth-child(6) {
      width: 175px;
    }

    &:nth-child(7) {
      width: 180px;
    }

    &:nth-child(8) {
      width: 110px;
    }

    &:nth-child(9) {
      width: 98px;
    }
  }
}

.apActiveToolbar {
  display: flex;

  > div {
    &:nth-child(1) {
      padding-left: 26px;
      width: 302px;
    }

    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5) {
      flex: 1;
    }

    &:nth-child(6) {
      width: 200px;
    }

    &:nth-child(7),
    &:nth-child(8) {
      width: 120px;
    }
  }
}

.selectField {
  width: 150px;
  height: 36px;
}
