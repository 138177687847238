@import '../../assets/styles/colors.scss';
@import '../../assets/styles/variables';

.outgoingWireTable {
  tbody {
    max-height: calc(100vh - 365px) !important;
  }
  tr {
    height: 38px;

    td,
    th {
      &:nth-child(1) {
        width: 30%;
      }

      &:nth-child(2) {
        width: 20%;
      }

      &:nth-child(4),
      &:nth-child(5) {
        width: 120px;
      }
    }
  }
}

.outgoingWireTableParticipants {
  tbody {
    max-height: calc(100vh - 365px) !important;
  }
  tr {
    height: 38px;

    td,
    th {
      &:nth-child(1) {
        width: 20%;
      }

      &:nth-child(2) {
        width: 20%;
      }
    }
  }
}

.activeToolbar {
  order: 1;

  > div {
    &:nth-child(1) {
      width: 30%;
    }

    &:nth-child(2) {
      width: 20%;
    }
  }
}

.activeToolbarParticipant {
  order: 1;

  > div {
    &:nth-child(1) {
      width: 40%;
    }

    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4) {
      width: 20%;
    }

    &:nth-child(5) {
      width: 35%;
    }
  }
}

.outgoingWireTableClientName {
  color: $secondary-color;
  font-weight: bold;
}

.outgoingWireTableSentButton {
  background: $green-background;
  color: $green-text !important;

  :hover {
    background: $green-background;
    color: $green-text;
  }

  display: inline-block;
  font-size: $main-font-size;
  line-height: 16px;
  padding: 3px 7px;
  border-radius: $small-border-radius;
  cursor: pointer;
}

.sendIcon {
  cursor: pointer;
  width: 18px;
  height: 18px;

  &:hover,
  &:focus {
    path {
      stroke: $cerulean;
    }
  }
}

.plusIcon {
  cursor: pointer;
  width: 18px;
  height: 18px;
  margin-right: 4px;

  &:hover,
  &:focus {
    path {
      stroke: $cerulean;
    }
  }

  path {
    stroke-width: 1px;
    stroke: $active-blue;
  }
}

.removeIcon {
  cursor: pointer;
}

.selectedItems {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 600;
  color: $secondary-color;
}

.sendAllButton {
  font-weight: 600;

  color: $white;
  background-color: $primary-color;
  fill: $primary-color;

  // make svg on hover white
  svg {
    fill: $white;
  }

  &:hover,
  &:focus {
    background-color: transparent;
    color: $primary-color;
  }
}

.modalRoot {
  padding: 15px 20px;
  width: 275px;
  min-width: 275px;
}

.modalClose {
  display: none;
}

.modalTitle {
  margin-bottom: 15px;
}

.modalFooter {
  margin-top: 15px;
  justify-content: space-between;
}

.modalInput {
  width: 100%;
}

.itemsCount {
  margin-top: 10px;
}

.wireAmountCell {
  white-space: inherit !important;
  color: $secondary-color;
}

.priceAdornment {
  color: $secondary-color;
}

.fieldLabel {
  text-align: left;
}

.payoffButton {
  margin-top: 10px;
}

.addPayoff {
  margin-left: 10px;
  height: 24px;
  cursor: pointer;
  background-color: $santas-gray;
  padding: 3px 5px;
  border-radius: $small-border-radius;
  color: $white;
}

.unqueueIcon {
  margin-left: 30px;
  transform: rotate(90deg);
}

.childBorders {
  position: absolute;
  top: 19px;
  left: 10px;
  height: 1px;
  width: calc(100% - 10px);
  background: $secondary-color;

  &:before {
    content: ' ';
    position: absolute;
    top: -19px;
    left: 0;
    height: 19px;
    width: 1px;
    background: $secondary-color;
  }

  &:after {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    height: 19px;
    width: 1px;
    background: $secondary-color;
  }
}

.childRow {
  td {
    &:nth-child(1) {
      position: relative;
      width: 5% !important;
    }

    &:nth-child(2) {
      width: 25% !important;
    }

    &:nth-child(3) {
      width: 20% !important;
    }

    &:nth-child(4) {
      width: unset !important;
    }

    &:nth-child(5),
    &:nth-child(6) {
      width: 120px;
    }
  }

  &:not(.lastChildRow) {
    td {
      &:nth-child(1) {
        border-bottom: none;
      }
    }
  }
}

.childRowParticipant {
  td {
    &:nth-child(1) {
      position: relative;
      width: 5% !important;
    }

    &:nth-child(2) {
      width: 25% !important;
    }

    &:nth-child(3) {
      width: 25% !important;
    }

    &:nth-child(4) {
      width: unset !important;
    }
  }

  &:not(.lastChildRow) {
    td {
      &:nth-child(1) {
        border-bottom: none;
      }
    }
  }
}

.lastChildRow {
  .childBorders {
    &:after {
      display: none;
    }
  }
}

.boxLink {
  margin-left: 20px;
}

.editIcon {
  margin-left: 10px;
  svg {
    height: 14px;
    width: 14px;
  }
}
.certificateContainer {
  display: flex;
  align-items: center;
}
.red {
  div {
    border-color: $red-text !important;
    background-color: $red-background !important;
  }
}
